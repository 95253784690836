// 添加面板
const addDevice = "/turnstile/deviceManage/addDevice";
// 编辑面板接口
const editDevice = "/turnstile/deviceManage/editDevice";
// 删除面板接口
const deleteDevice = "/turnstile/deviceManage/deleteDevice";
// 查询面板接口
const queryDevice = "/turnstile/deviceManage/queryDevice";
// 面板组下的公告数量
const queryDeviceAdvertisingCountUrl = '/turnstile/deviceManage/queryDeviceResource';
// 添加面板组接口
const addGroup = "/turnstile/deviceManage/addGroup";
// 修改面板组接口
const editGroup = "/turnstile/deviceManage/editGroup";
// 删除面板组接口
const deleteGroup = "/turnstile/deviceManage/deleteGroup";
// 查询面板组接口
const queryGroup = "/turnstile/deviceManage/queryGroup";
// 查询面板组内面板接口
const queryGroupDevices = "/turnstile/deviceManage/queryGroupDevices";
// 查询面板组内面板接口分页
const queryGroupDevicesPage = "/turnstile/deviceManage/queryGroupDevicesPage";
// 修改面板组限行状态
const updateRestrictionGroupDevicesUrl = '/turnstile/deviceManage/updateRestriction';
// 修改面板组体温检测状态
const updateCheckTemperatureGroupDevicesUrl = '/turnstile/deviceManage/openOrCloseCheckTemperature';
// 查看面板关联租客公司名称
const queryDeviceLessees = "/turnstile/deviceManage/queryDeviceLessees";
// 设置通行引导接口
const setupWelcomeMessage = "/turnstile/config/setupWelcomeMessage";
// 通行引导设置状态查询接口
const queryWelcomeMessage = "/turnstile/config/queryWelcomeMessage";
// 搜索商品列表
const itemList = "/public/mall/itemList";
// 企业服务接口
const userQueryItems = "/public/enterprise/userQueryItems";
// 上门服务接口
const serviceItems = "/public/visiting/userQueryItems";
// 查询,公告,活动接口
const userQueryOfficial = "/social/post/userQueryOfficial";
// 查询帖子
const postList = "/social/post/postList";
// 查询面板公告
const queryAdvertiseConfigUrl = '/turnstile/advertise/queryConfig';
// 添加面板公告
const addAdvertiseConfigUrl = '/turnstile/advertise/addConfig'
// 上下架公告
const updateConfigEnableUrl = '/turnstile/advertise/updateConfigEnable';
// 删除公告
const deleteAdvertiseConfigEnableUrl = '/turnstile/advertise/deleteConfig'
// 查询公告详情
const queryAdvertiseResourcesUrl = '/turnstile/advertise/queryResources';
// 修改面板公告
const editAdvertiseConfigUrl = '/turnstile/advertise/editConfig';
// 同步面板中的人脸照片
const syncFaces = "/turnstile/syncFaces";
// 同步面板的门卡信息
const sendCardEventByDeviceIdUrl = "/turnstile/businessDoorCart/sendCardEventByDeviceId";
// 3D园区中用的
// 查询ibeacon数据
const queryInspectionByCondition = "/inspection/inspection/queryInspectionByCondition";
// 查询闸机数据
const queryPassLogByCondition = "/turnstile/deviceManage/queryPassLogByCondition";
//已设置时间
const queryTaskRegionUrl = `/turnstile/scheduledTask/taskRegionCode`;
//闸机重启
const queryLaskListUrl = `/turnstile/scheduledTask/taskList`;
//设置时间
const queryStartUrl = '/turnstile/scheduledTask/start';
//关闭闸机任务
const setScheduledTaskStopUrl = "/turnstile/scheduledTask/stop";
//立即重启面板
const immediateRebootUrl = "/turnstile/deviceManage/immediateReboot";
//立即关闭广告机
const shutdownUrl = "/turnstile/upDown/advShutdown";
//定时开关机设置
const timingUpDownUrl = "/turnstile/upDown/addModification";
//查询定时开关机设置
const queryTimingUpDownUrl = "/turnstile/upDown/querySetting";

export {
    immediateRebootUrl,
    setScheduledTaskStopUrl,
    queryStartUrl,
    queryLaskListUrl,
    queryTaskRegionUrl,
    addDevice,
    editDevice,
    deleteDevice,
    queryDevice,
    addGroup,
    editGroup,
    deleteGroup,
    queryGroup,
    queryGroupDevices,
    queryDeviceLessees,
    setupWelcomeMessage,
    queryWelcomeMessage,
    itemList,
    userQueryItems,
    serviceItems,
    userQueryOfficial,
    postList,
    queryAdvertiseConfigUrl,
    addAdvertiseConfigUrl,
    updateConfigEnableUrl,
    deleteAdvertiseConfigEnableUrl,
    queryAdvertiseResourcesUrl,
    editAdvertiseConfigUrl,
    syncFaces,
    queryInspectionByCondition,
    queryPassLogByCondition,
    sendCardEventByDeviceIdUrl,
    queryDeviceAdvertisingCountUrl,
    updateRestrictionGroupDevicesUrl,
    queryGroupDevicesPage,
    shutdownUrl,
    timingUpDownUrl,
    queryTimingUpDownUrl,
    updateCheckTemperatureGroupDevicesUrl
}
