
// 查询位置列表
const queryLocationUrl = '/inspection/location/adminQuery';

// 查询特定的巡检详情
const queryInspectionUrl = "/inspection/perform/task";


// 添加巡检项
const createInspectionItemUrl = "/inspection/inspection/createInspectItems";
// 编辑巡检点
const editInspectionPointUrl = "/inspection/inspection/editInspectPoint";
//  禁用巡检点
const disableInspectPointUrl = "/inspection/inspection/disableInspectPoint";
//  创建巡检点
const createInspectionPointUrl = "/inspection/inspection/createInspectPoint";
// 查询巡检点
const queryInspectionPointUrl = "/inspection/inspection/queryInspectPoint";
// 查询巡检路线
const queryInspectionRouteUrl = "/inspection/inspection/queryInspectRoute";
// 创建巡检计划
const createInspectPlanUrl = "/inspection/inspection/createInspectPlan";
// 修改巡检计划
const editInspectPlanUrl = "/inspection/inspection/editInspectPlan";
// 获取巡检计划时间方案
const getInspectPlanScheduleDateUrl =
    "/inspection/inspection/getInspectPlanScheduleDate";
// 设置巡检点计划时间
const setupInspectPlanPerformerUrl =
    "/inspection/inspection/setupInspectPlanPerformer";
// 删除巡检计划
const deleteInspectPlanUrl = "/inspection/inspection/deleteInspectPlan";

// 查询巡检路线上的巡检点
const queryInspectRoutePointUrl =
    "/inspection/inspection/queryInspectRoutePoint";
// 创建巡检路线
const createInspectRouteUrl = "/inspection/inspection/createInspectRoute";
// 修改巡检路线
const editInspectRouteUrl = "/inspection/inspection/editInspectRoute";
// 删除巡检路线
const deleteInspectRouteUrl = "/inspection/inspection/deleteInspectRoute";
// 查询巡检任务
const queryInspectTaskUrl = "/inspection/inspection/queryInspectTask";
// 查询巡检任务的巡检点检查记录
const queryInspectPointLog = "/inspection/inspection/queryInspectPointLog";
// 查询检查项签到记录
const queryInspectItemLogUrl = "/inspection/inspection/queryInspectItemLog";
// 查询巡检计划
const queryInspectPlanUrl = "/inspection/inspection/queryInspectPlan";
// 增加责任区
const addResponsibilityArea = "/inspection/responsibilityArea/add";
// 删除责任区
const deleteResponsibilityArea = "/inspection/responsibilityArea/delete";
// 修改责任区
const editResponsibilityArea = "/inspection/responsibilityArea/edit";
// 查询责任区
const queryResponsibilityArea = "/inspection/responsibilityArea/adminQuery";

// 查询台账列表数据
const queryAccountUrl = "/inspection/ledger/adminQuery";
// 添加台账
const addAccountUrl = "/inspection/ledger/add";
// 删除台账
const deleteAccountUrl = "/inspection/ledger/delete";
// 编辑台账
const editAccountUtl = "/inspection/ledger/edit";
// 批量查询巡检点(台账)
const queryLedgerInspectPoint = "/inspection/ledger/queryLedgerInspectPoint";
// 查询登记记录
const queryRegister = "/inspection/registerLog/queryRegister";
// 查询巡检记录
const queryInspectLog = "/inspection/inspection/queryInspectLog";
// 查询巡检记录的巡检项
const queryInspectLogItemLog = "/inspection/inspection/queryInspectLogItemLog";
// 后台查询巡检记录
const adminQueryInspectLogUrl = "/inspection/inspection/adminQueryInspectLog";
// 物业数据查询巡检数据接口
const queryInspectionPropertyDataUrl = "/inspection/inspection/propertyData";
// 更改位置状态
const changeLocationStateUrl = '/inspection/location/changeLocationState';
// 添加位置接口
const addLocationUrl = '/inspection/location/insert';
// 查询与输入位置相似的位置
const querySimilarLocationUrl = '/inspection/location/querySimilarLocation'
// 查询关联事物
const queryRelatedTransactionUrl = '/inspection/location/queryRelatedTransaction';
export {
    queryLocationUrl,
    queryInspectionUrl,
    queryInspectTaskUrl,
    queryInspectPointLog,
    queryInspectItemLogUrl,
    queryInspectPlanUrl,
    createInspectPlanUrl,
    editInspectPlanUrl,
    getInspectPlanScheduleDateUrl,
    setupInspectPlanPerformerUrl,
    deleteInspectPlanUrl,
    disableInspectPointUrl,
    createInspectionItemUrl,
    editInspectionPointUrl,
    createInspectionPointUrl,
    queryInspectionPointUrl,
    queryInspectionRouteUrl,
    queryInspectRoutePointUrl,
    createInspectRouteUrl,
    editInspectRouteUrl,
    deleteInspectRouteUrl,
    addResponsibilityArea,
    deleteResponsibilityArea,
    editResponsibilityArea,
    queryResponsibilityArea,
    queryAccountUrl,
    addAccountUrl,
    deleteAccountUrl,
    editAccountUtl,
    queryLedgerInspectPoint,
    queryRegister,
    queryInspectLog,
    queryInspectLogItemLog,
    queryInspectionPropertyDataUrl,
    changeLocationStateUrl,
    addLocationUrl,
    querySimilarLocationUrl,
    queryRelatedTransactionUrl,
    adminQueryInspectLogUrl,
}