// 车牌绑定查询
const parkingPlateBindUrl = '/parking/plate/adminQuery';
// 解绑车牌
const unbindPlateUrl = '/parking/plate/unbind';
// 查询车辆付费记录
const queryParkingPaymentRecordsUrl = '/parking/paymentRecords/adminQuery';
// 车辆进出记录
const parkingInOutLogUrl = '/parking/inOutLog/adminQuery';
// 查询停车场优惠券
const  queryParkingCouponUrl = '/parking/counpon/adminQuery';
// 更改停车优惠券状态
const changeParkingCouponEnableUrl = '/parking/counpon/disableEnable';
// 添加停车场优惠券
const addParkingCouponUrl = '/parking/counpon/adminAdd';
// 停车场领取记录
const receiveCounponLogUrl = '/parking/couponLog/adminQuery';
// 查询第三方微信商户
const queryThirdPartyServicesUrl = '/payment/pay/property/queryNotBoundAccount';

//查询场内车辆
const queryVenueCarUrl = "/parking/inOutLog/queryVenueCar"
export {
    parkingPlateBindUrl,
    unbindPlateUrl,
    queryParkingPaymentRecordsUrl,
    parkingInOutLogUrl,
    queryParkingCouponUrl,
    changeParkingCouponEnableUrl,
    addParkingCouponUrl,
    receiveCounponLogUrl,
    queryThirdPartyServicesUrl,
    queryVenueCarUrl
}
