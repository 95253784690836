<template>
    <transition>
        <div class="layer" v-if="visible">
            <div
                class="cs-dialog"
                @click.stop="onClick"
                :style="{
                    '--dialog-width':
                        typeof width === 'number' ? width + 'px' : width,
                }"
            >
              <div :class="['cs-dialog-header', headerClass, 'headerIcon']" v-if="headerIcon">
                <svg class="icon" aria-hidden="true">
                  <use :xlink:href="'#'+headerIcon"></use>
                </svg>
              </div>
                <div :class="['cs-dialog-header', headerClass]" v-else-if="title && title !== ''">
                    {{ title }}
                </div>
                <slot name="dialog-content">
                    <template v-if="message != '' || messageHtml != ''">
                        <div class="cs-dialog-content">
                            <div
                                v-if="messageHtml && messageHtml !== ''"
                                v-html="messageHtml"
                            ></div>
                            <div
                                v-else
                                style="display: inline-block; text-align: left"
                            >
                                {{ message }}
                            </div>
                        </div>
                    </template>
                </slot>
                <div class="cs-dialog-footer" v-if="showCancelBtn || showConfirmBtn">
                    <slot name="dialog-footer">
                        <button
                            :class="['btn', 'btn-primary', {disabled: dialogShowConfirmBtnDisabled || submittingStatus}]"
                            @click.stop.enter="onConfirm"
                            v-if="showConfirmBtn"
                        >
                            {{ confirmBtnText }}
                        </button>
                        <button
                            class="btn btn-cancel"
                            @click.stop="onCancel"
                            v-if="showCancelBtn"
                            :style="showConfirmBtn ? '' : 'background: #1ab394; color: #fff;'"
                        >
                            {{ cancelBtnText }}
                        </button>
                    </slot>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "CSDialog",
    props: {
        dialogWidth: [Number, String],
        dialogVisible: Boolean,
        dialogTitle: String,
        dialogCancelBtnText: String,
        dialogConfirmBtnText: String,
        dialogHeaderClass: String,
        dialogHeaderIcon: {
          type: String,
          default: null,
        },
        dialogShowCancelBtn: {
            type: Boolean,
            default: null,
        },
        dialogShowConfirmBtnDisabled: {
          type: Boolean,
          default: false,
        },
        dialogShowConfirmBtn: {
            type: Boolean,
            default: null,
        },
        isSubmitting: {
            type: Boolean,
            default: false
        }
    },

    watch: {
        isSubmitting(val) {
            this.submittingStatus = val;
        },
        dialogVisible(val) {
            if (val) {
                this.visible = true;
                return;
            }
            this.onCancel();
        },
        dialogTitle(val) {
            this.title = val;
        },
        dialogConfirmBtnText(val){
            this.confirmBtnText = val;
        }
    },
    data() {
        return {
            visible: false,
            submittingStatus: false,
            title: "",
            message: "",
            width: "",
            confirmBtnText: "保存",
            cancelBtnText: "取消",
            showConfirmBtn: true,
            showCancelBtn: true,
            headerClass: 'operation-bg',
            headerIcon: '',
            messageHtml: "",
        };
    },
    created() {
        this.width = this.dialogWidth || this.width;
        this.visible = this.dialogVisible || this.visible;
        if (this.dialogTitle && this.dialogTitle !== "") {
            this.title = this.dialogTitle;
        }
        if (this.dialogCancelBtnText && this.dialogCancelBtnText !== "") {
            this.cancelBtnText = this.dialogCancelBtnText;
        }
        if (this.dialogConfirmBtnText && this.dialogConfirmBtnText !== "") {
            this.confirmBtnText = this.dialogConfirmBtnText;
        }
        if (this.dialogShowCancelBtn !== null) {
            this.showCancelBtn = this.dialogShowCancelBtn;
        }
        if (this.dialogShowConfirmBtn !== null) {
            this.showConfirmBtn = this.dialogShowConfirmBtn;
        }
        this.headerClass = this.dialogHeaderClass || this.headerClass;
        this.headerIcon = this.dialogHeaderIcon || this.headerIcon;
    },
    updated() {
        document.getElementsByTagName('select').forEach(select => {
            if (select.value !== '') {
              select.classList.add('selected');
            }else {
              select.classList.remove('selected');
            }
            select.addEventListener('change', (event) => {
              const {selectedIndex} = event.target;
              if (event.target[selectedIndex].value !== '' && event.target[selectedIndex].value !== undefined) {
                event.target.classList.add('selected');
              } else {
                event.target.classList.remove('selected');
              }
            })
        })
    },
    methods: {
        onClick() {
            this.$emit("click");
        },
        closeDialog() {
            this.$emit("onClose");
            this.submittingStatus = false;
            this.visible = false;
        },
        onConfirm() {
            if (this.submittingStatus) {
                this.$vc.message('请不要重复提交');
                return ;
            }
            if (this.dialogVisible) {
                this.$emit("onConfirm");
            }
        },
        onCancel() {
            this.closeDialog();
        },
    },
};
</script>

<style lang="stylus" scoped>
.cs-dialog
    width var(--dialog-width, 944px)
    background #fff
    box-shadow 0 5px 10px 0 rgba(0, 0, 0, 0.1)
    border-radius 10px
    margin 80px auto
    color #000
    //overflow hidden
    &-header
        border-top-left-radius 10px
        border-top-right-radius 10px
        padding 15px
        font-size 24px
        line-height 24px
        font-weight bold
        text-align center
        position relative
        &.headerIcon
          font-size 92px
          padding 30px 15px
          padding-bottom 0
          &::after
            display none
        &.operation-bg
            background var(--pc-theme-color, '#1ab394')
            color #fff
        &.alert-bg
            background #fff
            color #1ab394
            padding 15px 30px
            line-height 30px
        &::after
            width 100%
            height 1px
            position absolute
            right 0
            bottom 0
            content ' '
            background #f0f0f0
    &-content
        padding 30px
        text-align center
        font-size 24px
        word-wrap break-word
        div
            max-width 100%
            word-wrap break-word
    &-footer
        padding 20px
        position relative
        text-align center
        .btn
            // width 80px
            height 40px
            border-radius 6px
            font-size 24px
            line-height 30px
            padding 0 10px
            &.disabled
                background #999
                border-color #999
                pointer-events auto
                cursor not-allowed
            &:hover
                color rgba(255, 255, 255, .5)
            &:not(:last-of-type)
                margin-right 60px
            &.btn-cancel
                box-shadow 0 0 0 0 rgba(255, 255, 255, 1)
                background #fff
                border 1px solid #1ab394
                color #1ab394
        &::before
            position absolute
            top 0
            left 0
            width 100%
            height 1px
            background #f0f0f0
            content ' '
@media screen and (max-width: 1400px)
    @supports(zoom: 0.8)
        .cs-dialog
            zoom 0.8
            margin calc(80px * 0.4) auto
    @supports not (zoom: 0.8)
        .cs-dialog
            transform scale(0.8)
            margin calc(80px * 0.4) auto
</style>
