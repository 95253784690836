// 创建无人机
const createDroneUrl = '/turnstile/drone/create';
// 编辑无人机
const editDroneUrl = '/turnstile/drone/update';
// 删除无人机
const deleteDroneUrl = '/turnstile/drone/delete';
// 查询无人机
const queryDroneListUrl= '/turnstile/drone/adminQuery';
// 查询无人机机场
const queryDroneAirportUrl = '/turnstile/airport/adminQuery';
// 添加无人机机场
const addDroneAirportUrl = '/turnstile/airport/insert';
// 修改无人机机场
const  editDroneAirportUrl = '/turnstile/airport/update';
// 删除无人机机场
const deleteDroneAirportUrl = '/turnstile/airport/delete';
//开关接口
const sendCommandUrl = '/turnstile/airport/wifi/sendCommand';
// 查询飞行任务
const queryFlightMissionUrl = '/turnstile/task/adminQuery';
// 添加飞行任务
const addFlightMissionUrl = '/turnstile/task/insert';
// 删除飞行任务
const deleteFlightMissionUrl = '/turnstile/task/delete';
// 编辑飞行任务
const editFlightMissionUrl = '/turnstile/task/update';
// 坐标转换接口
const coordConvertionUrl = '/turnstile/task/coordConvertion';
// 飞行记录
const queryFlightLogUrl = '/turnstile/log/adminQuery';
// 查询与机场绑定的无人机列表
const queryBindAirportDronesUrl = '/turnstile/bind/queryCurrentBind';
// 解除无人机与机场绑定
const unbindAirportForDroneUrl = '/turnstile/bind/unbind';
// 查询无人机与机场的解绑、绑定记录
const queryBindAirportLogsUrl = '/turnstile/bind/queryLog';
// 绑定无人机与机场
const bindAirportAndDroneUrl = '/turnstile/bind/add';
//查询wifi设备
const queryWifiDeviceUrl =  '/turnstile/airport/wifi/queryWifiDevice';
// 启动无人机飞行
const startDroneFlightUrl = '/turnstile/drone/flight';
// 获取无人机画面
const getDroneRVCAddrUrl = '/turnstile/drone/getRVCAddr';

const queryDroneBigDataUrl = '/turnstile/bind/bigData';
// 取消飞行
const cancelFlightUrl = '/turnstile/drone/cancelFlight';
// 手动返航
const droneGoHomeUrl = '/turnstile/drone/goHome';
// 设置飞行航点图
const setFlightPointImgUrl = '/turnstile/task/uploadPicture';
// 获取可设置wifi的员工
const getIsSettOpenWifiUrl = '/turnstile/airport/wifi/getIsSettOpenWifi';
// 设置员工WIFI
const setStaffOpenWifiUrl = '/turnstile/airport/wifi/setStaffOpenWifi';

export {
    sendCommandUrl,
    createDroneUrl,
    editDroneUrl,
    deleteDroneUrl,
    queryDroneListUrl,
    queryDroneAirportUrl,
    deleteDroneAirportUrl,
    editDroneAirportUrl,
    addDroneAirportUrl,
    queryFlightMissionUrl,
    addFlightMissionUrl,
    deleteFlightMissionUrl,
    queryWifiDeviceUrl,
    editFlightMissionUrl,
    coordConvertionUrl,
    queryFlightLogUrl,
    queryBindAirportDronesUrl,
    unbindAirportForDroneUrl,
    queryBindAirportLogsUrl,
    bindAirportAndDroneUrl,
    startDroneFlightUrl,
    getDroneRVCAddrUrl,
    queryDroneBigDataUrl,
    cancelFlightUrl,
    droneGoHomeUrl,
    setFlightPointImgUrl,
    getIsSettOpenWifiUrl,
    setStaffOpenWifiUrl
}
