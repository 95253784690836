import CSDialog from "@/components/common/CSDialog";

/**
 * 转化成vue组件需要的参数
 * @param {Object} options 调用方法传入的属性和方法
 *  */
function toVueOptions(options) {
  let data = {},
    methods = {};
  for (let key in options) {
    if (options.hasOwnProperty(key)) {
      if (options[key] instanceof Function) {
        methods[key] = options[key];
      } else {
        data[key] = options[key];
      }
    }
  }
  return {
    data() {
      return { ...data };
    },
    methods,
  };
}

const Dialog = {
  vue: null,
  options: {
    width: "580px",
  },
  instance: null,
  init(Vue, options) {
    const CSDialogConstructor = Vue.extend(CSDialog),
      instance = new CSDialogConstructor(
        toVueOptions({ ...this.options, ...options })
      );
    instance.$mount(document.createElement("div"));
    document.body.appendChild(instance.$el);
    this.instance = instance;
    return instance;
  },
  confirm(options) {
    options = {
      visible: true,
      showCancelBtn: true,
      showConfirmBtn: true,
      confirmBtnText: "确定",
      cancelBtnText: "取消",
      headerIcon: 'icon-menua-zu13',
      headerClass: '',
      ...options,
    };
    this.init(this.vue, options);
  },
  alert(options) {
    options = {
      visible: true,
      showCancelBtn: true,
      showConfirmBtn: false,
      headerClass: 'alert-bg',
      cancelBtnText: "关闭",
      ...options,
    };

    this.init(this.vue, options);
  },
  warning(options) {
    options = {
      headerIcon: 'icon-menua-zu13',
      // title: "提示",
      message: "确定操作吗？",
      visible: true,
      showCancelBtn: true,
      showConfirmBtn: true,
      confirmBtnText: "确定",
      headerClass: ' ',
      cancelBtnText: "取消",
      ...options,
    };
    this.init(this.vue, options);
  },
  remove(instance) {
    document.body.querySelector(instance.$el).remove();
  },
};

function install(Vue, options = {}) {
  if (Dialog.vue) {
    return;
  }
  Dialog.vue = Vue;
  Dialog.options = { ...Dialog.options, ...options };
  Vue.prototype.$CSDialog = Dialog;
}

export default {
  install,
  Dialog,
};
