// 添加仪表
const addInstrumentUrl = "/meter/meter/add";
// 搜索仪表
const queryInstrumentUrl = "/meter/meter/query";
// 搜索抄表记录
const queryMeterReading = "/meter/meterReading/query";
// 修改仪表
const editInstrumentUrl = "/meter/meter/edit";
// 根据仪表名称查询抄表记录
const queryByNameUrl = "/meter/meterReading/queryByName";
// 根据仪表id查询仪表详情
const queryMeterByIdUrl = '/meter/meter/getById';
// 删除仪表
const deleteInstrumentUrl = "/meter/meter/delete";

// 查询抄表记录
const queryMaterRecord = "/meter/meterReading/queryMaterRecord";
//查询所有创建账单用户
const queryAllCreateUserUrl = '/meter/collect/queryAllCreateUser';
export {
  addInstrumentUrl,
  queryInstrumentUrl,
  queryMeterReading,
  editInstrumentUrl,
  queryByNameUrl,
  queryMeterByIdUrl,
  deleteInstrumentUrl,
  queryMaterRecord,
  queryAllCreateUserUrl
};
