import {OPERATIONAL_ITEM_TYPE} from "@/views/Statistics/operationalItemType";
import {loadComponent, loadView} from './loadFn';
import WaterElectricityMeterRoutes from './WaterElectricityMeterRoutes';
import MeetingRoomRoutes from './MeetingRoomRoutes';
import EnterpriseServiceRoutes from './EnterpriseServiceRoutes';
import AnnouncementRoutes from './AnnouncementRoutes';
import SecurityCameraRoutes from './SecurityCameraRoutes';
import ParkRoutes from './ParkRoutes';
import DroneRoutes from './DroneRoutes';
import QualityRoutes from './QualityRoutes';
import OnlyOnePageRoutes from './OnlyOnePageRoutes';
import RobotRoutes from "@/router/RobotRoutes";

const basePath = "/flow";


const routes = [{
    path: "",
    name: "defaultPage",
    component: loadView("Login"),
},
    {
        path: basePath + "/login",
        name: "login",
        component: loadView("Login"),
    },
    {
        path: basePath + "/register",
        name: "register",
        component: loadView("Register"),
    },
    {
        path: basePath + "/shortNoteBill",
        name: "shortNoteBill",
        component: loadComponent("shortNoteBill"),
        props: (route) => ({keyk: route.query.key}),
    },
    {
        path: basePath,
        component: loadView("MainPage"),
        children: [{
            path: "environmentalMonitoring",
            name: "EnvironmentalMonitoring",
            component: loadView("EnvironmentalMonitoring/EnvironmentalMonitoring"),
        },
            {
                path: "RoomState",
                name: "RoomState",
                meta: {
                    title: "租控图",
                },
                component: loadComponent("RoomState"),
            },
            {
                path: "RoomState/:roomId/RoomInfo",
                name: "RoomInfo",
                meta: {
                    title: "房间",
                },
                props: (route) => ({roomId: +route.params.roomId}),
                component: loadComponent("RoomInfo"),
            },
            {
                path: "WorkOrderBill",
                name: "WorkOrderBill",
                component: loadComponent("WorkOrderBill"),
            },
            WaterElectricityMeterRoutes,
            {
                path: "AssessmentSummaryFlow",
                name: "AssessmentSummaryFlow",
                meta: {
                    title: "考核汇总",
                },
                component: loadView("AssessManage/AssessmentSummary"),
            },
            {
                path: "BindingVendorConfigFlow",
                name: "BindingVendorConfigFlow",
                component: loadView("BindingVendorConfig"),
            },
            {
                path: "assessmentManageFlow",
                name: "assessmentManageFlow",
                component: loadView("AssessManage/AssessmentManage"),
            },
            {

                path: "assessmentAutoGradeDetail/:id/:queryType",
                name: "assessmentAutoGradeDetail",
                component: loadComponent("AssessmentSummaryDetail"),
                props: (route) => ({
                    ...route.params,
                    id: +route.params.id,
                    blockId: +route.query.blockId,
                    type: +route.params.queryType,
                }),
                meta: {
                    title: "系统考核扣分",
                },
                beforeEnter: (to, form, next) => {
                    to.meta.title = `系统考核${to.params.queryType === 1 ? "加" : "扣"
                    }分`;
                    next();
                },
            },
            {
                path: "assessmentProGradeDetail/:id",
                name: "assessmentProGradeDetail",
                component: loadComponent("AssessmentSummaryDetail"),
                props: (route) => ({
                    id: +route.params.id,
                    blockId: +route.query.blockId,
                }),
                meta: {
                    title: "业务考核扣分",
                },
            },
            {
                path: "assessmentGenGradeDetail/:id",
                name: "assessmentGenGradeDetail",
                component: loadComponent("AssessmentSummaryDetail"),
                props: (route) => ({
                    id: +route.params.id,
                    blockId: +route.query.blockId,
                }),
                meta: {
                    title: "通用考核扣分",
                },
            },
            {
                path: "assignTimeoutConfigFlow",
                name: "assignTimeoutConfigFlow",
                component: loadView("AssignTimeoutConfig"),
            },
            {
                path: "staff/checkIn",
                name: "attendanceLogFlow",
                component: loadView("AttendanceScheduleManage/AttendanceLog"),
            },
            {
                path: "htAttendanceClockrl",
                name: "attendanceClock",
                component: loadView("AttendanceScheduleManage/AttendanceSummary")
            },
            {
                path: "_attendanceSchedule",
                name: "attendanceSchedule",
                component: loadView("AttendanceScheduleManage/AttendanceSchedule"),
            },
            {
                path: "_attendanceSchedule/detail/:id",
                name: "attendanceScheduleDetail",
                props: (route) => {
                    return {
                        id: Number(route.params.id),
                    };
                },
                component: loadView(
                    "AttendanceScheduleManage/AttendanceScheduleDetail"
                ),
            },
            {
                path: "billFlow",
                name: "billFlow",
                meta: {
                    title: "合同账单",
                },
                component: loadView("ContractBill/BillManage"),
            },
            {
                path: "bill/contract/:id/:payAmount/detail",
                name: "contractBillDetail",
                meta: {
                    title: "账单详情",
                },
                props: (route) => {
                    return {id: +route.params.id, payAmount: +route.params.payAmount};
                },
                component: loadView("ContractBill/BillDetail"),
            },
            {
                path: "tenantFlow",
                name: "tenantFlow",
                meta: {
                    title: "租客管理",
                },
                component: loadComponent("ListTenant"),
            },
            {
                path: "region",
                name: "communityFlow",
                component: loadView("CommunityManage"),
            },
            {
                path: "contractManageFlow",
                name: "contractManageFlow",
                meta: {
                    title: "合同管理",
                },
                component: loadView("ContractManage"),
            },
            {
                path: "propertyContract",
                name: "PropertyContract",
                meta: {
                    title: "物业合同"
                },
                component: loadView("PropertyContract"),
            },
            {
                path: "utilityBill",
                name: "UtilityBill",
                meta: {
                    title: '物业账单'
                },
                component: loadView("UtilityBill/utilityBill")
            },
            {
                path: "contractManageFlow/OtherExpenses",
                name: "OtherExpenses",
                meta: {
                    title: "其它费用",
                },
                props: (route) => ({charges: route.query.charges}),
                component: loadView("OtherExpenses"),
            },
            {
                path: "contractManageFlow/Enclosure",
                name: "Enclosure",
                meta: {
                    title: "附件",
                },
                component: loadView("Enclosure"),
            },
            {
                path: "editAutoAssessmentConfigFlow",
                name: "editAutoAssessmentConfigFlow",
                component: loadView("AssessManage/EditAutoAssessmentConfig"),
            },
            {
                path: "floorFlow",
                name: "floorFlow",
                component: loadComponent("ListFloor"),
            },
            {
                path: 'elevator',
                name: 'elevator',
                meta: {
                    title: '电梯管理'
                },
                component: loadView('elevator')
            },
            {
                path: "handoverLogFlow",
                name: "handoverLogFlow",
                component: loadView("HandoverLog"),
            },
            {
                path: "index",
                name: "index",
                component: loadView("IndexContext"),
            },
            {
                path: "InspectPoint",
                name: "InspectPoint",
                component: loadComponent("Inspection/InspectionPointManage"),
            },
            {
                path: "inspect/task",
                name: "inspectTaskFlow",
                meta: {
                    title: "巡检任务",
                },
                component: loadComponent("Inspection/InspectionTaskManage"),
            },
            {
                path: "inspect/inspectCheckDetail/:pointLogId",
                name: "inspectionCheckDetail",
                component: loadComponent("Inspection/InspectionCheckDetail"),
                props: (route) => ({
                    id: +route.params.pointLogId,
                }),
                meta: {
                    title: "检查项",
                },
            },
            {
                path: "inspect/plan",
                name: "inspectionPlanFlow",
                component: loadView("Inspection/InspectionPlanManage"),
            },
            {
                path: "inspect/route",
                name: "inspectionRouteFlow",
                component: loadView("Inspection/InspectionRouteManage"),
            },
            {
                path: "mallManageFlow",
                name: "mallManageFlow",
                component: loadView("MallManage"),
            },
            // ,{
            //     path: 'menuFlow',
            //     name: "menuFlow",
            //     component: loadView('Menu,
            // }
            // ,{
            //     path: 'menuGroupFlow',
            //     name: "menuGroupFlow",
            //     component: loadView('Menu,
            // }
            {
                path: "ownerManagement",
                name: "Owner",
                meta: {
                    title: "业主管理"
                },
                component: loadView("ownerManagement")
            },
            {
                path: "waterInfo",
                name: "waterInfo",
                meta: {
                    title: "水表剩余预存金额"
                },
                component: loadView("WaterElectricityMeter/LesseeWaterInfo")
            },
            {
                path: "waterGroupInfo",
                name: "waterGroupInfo",
                meta: {
                    title: '水表'
                },
                component: loadView("WaterElectricityMeter/GroupWater")
            },
            {
                path: "electricityGroupInfo",
                name: "electricityGroupInfo",
                meta: {
                    title: '电表'
                },
                component: loadView("WaterElectricityMeter/GroupElectricity")
            },
            {
                path: "electricityInfo",
                name: "electricityInfo",
                meta: {
                    title: "电表剩余预存金额"
                },
                component: loadView("WaterElectricityMeter/LesseeElectricityInfo")
            },
            {
                path: "individual",
                name: "individual",
                meta: {
                    title: "企业列表"
                },
                component: loadView("lease/Individual")
            },
            {
                path: "enterprise",
                name: "enterprise",
                meta: {
                    title: "企业列表"
                },
                component: loadView("lease/Enterprise")
            },
            {
                path: "deputy",
                name: "deputy",
                meta: {
                    title: "对接人列表"
                },
                component: loadView("lease/Deputy")
            },
            {
                path: "decoration",
                name: "decoration",
                meta: {
                    title: "装修审批"
                },
                component: loadView("decoration/Decoration")
            },
            {
                path: "decorationInfo/:id",
                name: "decorationInfo",
                meta: {
                    title: "装修申报审批",
                },
                props: (route) => ({
                    id: +route.params.id,
                }),
                component: loadView("decoration/DecorationInfo"),
            },
            {
                path: "orgFlow",
                name: "orgFlow",
                component: loadView("OrgManage"),
            },
            {
                path: "passRecordFlow",
                name: "passRecordFlow",
                component: loadView("DeviceManagement/PassRecord"),
            },
            {
                path: 'rentalFlow',
                name: "rentalFlow",
                component: loadView('RentalManage'),
            },
            {
                path: "infoRoom",
                name: "infoRoom",
                meta: {
                    title: '房间管理'
                },

                component: loadView("Room"),
            },
            {
                path: "infoRoomroomInfoPrivate",
                name: "roomInfoPrivate",
                component: loadView("RoomInfoPrivate"),

                beforeEnter: (to, form, next) => {
                    to.meta.title = `${to.params.roomNo ? to.params.roomNo  : ""}`;
                    next();
                },
            },
            {
                path: "roomPrivate",
                name: "roomPrivate",
                component: loadView("RoomListInfoPrivate"),

                beforeEnter: (to, form, next) => {
                    to.meta.title = `${to.params.roomNo ? to.params.roomNo  : ""}`;
                    next();
                },
            },
            {
                path: "room",
                name: "room",
                meta: {
                    title: '房间管理'
                },
                component: loadView("RoomList"),
            },
            {
                path: "staff",
                name: "staff",
                meta: {
                    title: '员工管理'
                },
                component: loadView("Staff"),
            },
            {
                path: "employee",
                name: "employee",
                meta: {
                    title: '员工管理'
                },
                component: loadView("NewStaff"),
            },
            {
                path: "workOrderFlow",
                name: "workOrderFlow",
                meta: {
                    title: '工单管理'
                },
                component: loadView("WorkOrderManage"),
            },
            {
                path: "workOrder/type/config",
                name: "ticketTypeEnable",
                meta: {
                    title: '工单类型设置'
                },
                component: loadView("TicketTypeConfig"),
            },
            MeetingRoomRoutes,
            {
                name: "meetingRoomBill",
                path: "meeting/bill",
                component: loadView("MeetingRoom/MeetingRoomBill"),
            },
            EnterpriseServiceRoutes,
            {
                path: "EnterpriseServiceBill",
                name: "EnterpriseServiceBill",
                component: loadView("EnterpriseServiceBill"),
            },
            AnnouncementRoutes,
            {
                path: "ActivityAdministrator",
                name: "ActivityAdministrator",
                meta: {
                    title: "活动管理",
                },
                component: loadView("ActivityAdministrator"),
            },
            {
                path: "ActivityAdministrator/DataStatistics/:id/:currentDataStatistics",
                name: "DataStatistics",
                meta: {
                    title: "数据统计",
                },
                props: (route) => ({
                    id: +route.params.id,
                    currentDataStatistics: JSON.parse(route.params.currentDataStatistics),
                }),
                component: loadView("DataStatistics"),
            },
            {
                path: "VisitService",
                name: "VisitService",
                component: loadView("VisitingService/VisitService"),
            },
            {
                path: "visitingServiceBill",
                name: "visitingServiceBill",
                component: loadView("VisitingService/VisitingServiceBill"),
            },
            {
                path: "questionnaire",
                name: "questionnaireManage",
                meta: {
                    title: "问卷管理",
                },
                component: loadView("QuestionnaireManage/QuestionnaireManage"),
            },
            {
                path: "questionnaire/add",
                name: "addQuestionnaire",
                meta: {
                    title: "添加问卷"
                },
                component: loadView("QuestionnaireManage/AddQuestionnaire"),
            },
            {
                path: "questionnaire/log",
                name: "questionnaireLog",
                meta: {
                    title: "数据统计",
                },
                component: loadView("QuestionnaireManage/QuestionnaireLog"),
                props: (route) => ({id: route.query.id}),
            },
            {
                path: "questionnaire/log/Submitter",
                name: "Submitter",
                meta: {
                    title: "提交人",
                },
                props: (route) => ({
                    id: +route.params.id,
                    answer: route.params.answer,
                    surveyId: +route.params.surveyId,
                }),
                component: loadView("QuestionnaireManage/Submitter"),
            },
            {
                path: "access/resource",
                name: "accessManage",
                component: loadView("AccessManage/ResourceManage"),
            },
            {
                path: "access/role",
                name: "roleManage",
                component: loadView("AccessManage/RoleManage"),
            },
            {
                path: "community",
                name: "CommunityManage",
                meta: {
                    title: "社区管理",
                },
                component: loadView("Community/CommunityManage"),
            },
            {
                path: "community/numberOfReplies",
                name: "NumberOfReplies",
                meta: {
                    title: "回复人数",
                },
                props: (route) => ({
                    id: +route.params.id,
                    deleteInvitationId: +route.params.id,
                }),
                component: loadView("Community/NumberOfReplies"),
            },
            {
                path: "fruiterStatistics",
                name: "fruiterStatistics",
                meta: {
                    title: "苹果兑换数据",
                },
                component: loadView("fruiterAdministration/fruiterStatistics"),
            },
            {
                path: "fruiterStatistics/AppleRedEnvelopeData",
                name: "AppleRedEnvelopeData",
                meta: {
                    title: "苹果红包详情",
                },
                props: (route) => ({id: +route.params.redPackId}),
                component: loadView("fruiterAdministration/AppleRedEnvelopeData"),
            },
            {
                path: "fruiterStatistics/SourceOfApple",
                name: "SourceOfApple",
                meta: {
                    title: "苹果来源",
                },
                props: (route) => ({id: +route.params.id}),
                component: loadView("fruiterAdministration/SourceOfApple"),
            },
            {
                path: "apple/redPacket",
                name: "appleRedPacket",
                component: loadView("fruiterAdministration/AppleRedPacket"),
            },
            {
                path: "apple/config/common",
                name: "appleConfig",
                component: loadView("fruiterAdministration/AppleCommonConfig"),
            },
            {
                path: "apple/config/create",
                name: "appleProduceSettings",
                component: loadView("fruiterAdministration/appleProduceSettings"),
            },
            {
                path: "propertyData",
                name: "propertyData",
                meta: {
                    title: "物业数据",
                },
                component: loadView("PropertyData"),
            },
            {
                path: "customer/leads",
                name: "customerLeads",
                component: loadView("InvestmentManagement/CustomerLeads"),
                meta: {
                    title: "电子楼书"
                }
            },
            {
                path: "customer/leads/uv",
                name: "uv",
                component: loadView("InvestmentManagement/UV"),
                meta: {
                    title: "UV独立访客"
                },
                beforeEnter: (to, form, next) => {
                    to.meta.title = `${to.params.name ? to.params.name : ""}UV独立访客`;
                    next();
                },
            },
            {
                path: "customer/leads/pv",
                name: "pv",
                component: loadView("InvestmentManagement/PV"),
                meta: {
                    title: "PV访问量"
                },
                beforeEnter: (to, form, next) => {
                    to.meta.title = `${to.params.name ? to.params.name : ""}PV访问量`;
                    next();
                }
            },
            {
                path: "customer/manage",
                name: "customerManage",
                component: loadView("InvestmentManagement/CustomerManage"),
            },
            {
                path: "customer/audit",
                name: "customerAudit",
                component: loadView("InvestmentManagement/CustomerAudit"),
            },
            {
                path: "customer/:leadId/:tabId/:sourceType/:staffId/info",
                name: "customerInfo",
                props: (route) => ({
                    leadId: +route.params.leadId,
                    tabId: +route.params.tabId,
                    sourceType: +route.params.sourceType,
                    staffId: +route.params.staffId,
                }),
                component: loadView("InvestmentManagement/CustomerInfo"),
            },
            {
                path: "ledger",
                name: "ledger",
                meta: {
                    title: "台账管理",
                },
                component: loadView("Ledger/Ledger"),
            },
            {
                path: "responsibilityArea",
                name: "responsibilityArea",
                meta: {
                    title: "责任区管理",
                },
                component: loadView("ResponsibilityArea/ResponsibilityArea"),
            },
            {
                path: "point",
                name: "point",
                meta: {
                    title: "巡检点",
                },
                component: loadView("LedgerPoint"),
                props: (route) => ({pointIds: route.query.pointIds}),
            },
            {
                path: "point/AccessRecords/:locationId",
                name: "AccessRecords",
                meta: {
                    title: "访问记录",
                },
                props: (route) => ({locationId: +route.params.locationId}),
                component: loadView("ResponsibilityArea/AccessRecords"),
            },
            {
                path: "point/AccessRecords/Checkeditem",
                name: "Checkeditem",
                meta: {
                    title: "检查项",
                },
                props: (route) => ({pointLogId: +route.params.pointLogId}),
                component: loadView("ResponsibilityArea/Checkeditem"),
            },
            {
                path: "personBase",
                name: "personBase",
                component: loadView("PersonBase/PersonBase"),
                meta: {
                    title: "资料管理",
                },
            },
            {
                path: "lookData",
                name: "lookData",
                component: loadView("PersonBase/LookData"),
                props: (route) => ({
                    folderId: Number(route.query.folderId),
                    folderCreateId: Number(route.query.folderCreateId),
                }),
                meta: {
                    title: "资料",
                },
            },
            {
                path: "panelManagement",
                name: "panelManagement",
                meta: {
                    title: "面板管理",
                },
                component: loadView("DeviceManagement/PanelManagement"),
            },
            {
                path: "panelManagement/numberOfPanels",
                name: "numberOfPanels",
                meta: {
                    title: "面板数量",
                },
                props: (route) => ({groupId: +route.params.groupId}),
                component: loadView("DeviceManagement/NumberOfPanels"),
            },
            {
                path: "photosDistributed",
                name: "photosDistributed",
                component: loadView("DeviceManagement/photosDistributed"),
            },
            {
                path: "guidePassage",
                name: "guidePassage",
                component: loadView("DeviceManagement/GuidePassage"),
            },
            ...QualityRoutes,
            {
                path: "appletsData",
                name: "appletsData",
                component: loadView("Statistics/AppletsData"),
            },
            {
                path: "operationalData",
                name: "operationalData",
                meta: {
                    title: "运营数据",
                },
                component: loadView("Statistics/OperationalData"),
            },
            {
                path: "operational/data/item/detail/:type",
                name: "operationalItemDataDetail",
                meta: {
                    title: "详情",
                },
                props: (route) => {
                    return {
                        ...route.query,
                        type: +route.params.type,
                    };
                },
                component: loadView("Statistics/OperationalItemDetail"),
                beforeEnter: (to, from, next) => {
                    const metaTitles = {
                        [OPERATIONAL_ITEM_TYPE.QUESTIONNAIRE_DATA]: "问卷明细",
                        [OPERATIONAL_ITEM_TYPE.COMPLAINT_DATA]: "投诉明细",
                        [OPERATIONAL_ITEM_TYPE.ACTIVE_DATA]: "活动明细",
                    };
                    to.meta.title = metaTitles[to.params.type];
                    next();
                },
            },
            {
                path: "warehouse/warehouseManage",
                name: "warehouseManage",
                component: loadView("warehouse/warehouse"),
            },
            {
                path: "warehouse/itemManage",
                name: "itemManage",
                component: loadView("warehouse/itemManage"),
            },
            {
                path: "warehouse/supplier",
                name: "supplier",
                component: loadView("warehouse/supplier"),
            },
            {
                path: "warehouse/mission",
                name: "mission",
                component: loadView("warehouse/mission"),
            },
            {
                path: "inspect/location",
                name: "locationManage",
                meta: {
                    title: "位置",
                },
                component: loadView("Inspection/LocationManage"),
            },
            {
                path: "inspect/location/:id/related",
                name: "relatedTransaction",
                meta: {
                    title: "关联事物",
                },
                props: (route) => {
                    return {id: +route.params.id};
                },
                component: loadView("Inspection/LocationRelatedTransaction"),
            },
            {
                path: "inspect/location/checkInLog/:id",
                name: "locationCheckInLog",
                meta: {
                    title: "登记记录",
                },
                props: (route) => ({id: +route.params.id}),
                component: loadView("Inspection/LocationCheckInLog"),
            },
            {
                path: "camera/capturePhotoLog/:faceId",
                name: "capturePhotoLog",
                meta: {
                    title: "人脸抓拍",
                },
                props: (route) => ({faceId: +route.params.faceId}),
                component: loadView("SecurityCamera/capturePhotoLog"),
            },
            {
                path: "inspect/location/checkInLog/patrolTask/:id",
                name: "patrolTask",
                meta: {
                    title: "巡检点",
                },
                props: (route) => ({id: +route.params.id}),
                component: loadView("Inspection/InspectionDetails"),
            },
            {
                path: "inspect/location/checkInLog/patrolTask/checkItems/:id",
                name: "checkItems",
                meta: {
                    title: "检查项",
                },
                props: (route) => ({id: +route.params.id}),
                component: loadView("Inspection/CheckItems"),
            },
            {
                path: `warehouse/warehouseManage/:id/:tabBar`,
                name: "file",
                props: (route) => ({
                    id: +route.params.id,
                    tabBar: +route.params.tabBar,
                }),
                component: loadView("warehouse/secondDetail"),
            },
            {
                path: "smartMeter/instrumentManagement",
                name: "instrumentManagement",
                // component: loadView("SmartMeter/InstrumentManagement"),
                component: loadView("SmartMeter/SmartMeter"),
            },
            {
                path: "smartMeter/Record",
                name: "meterRecord",
                component: loadView("SmartMeter/meterRecord"),
            },
            {
                path: "mobileHomeInspection/homeInspectionItem",
                name: "homeInspectionItem",
                component: loadView("mobileHomeInspection/homeInspectionItem"),
            },
            {
                path: "mobileHomeInspection/homeInspectionRecords",
                name: "homeInspectionRecords",
                meta: {
                    title: "验房记录",
                },
                component: loadView("mobileHomeInspection/homeInspectionRecords"),
            },
            {
                path: "mobileHomeInspection/homeInspectionRecords/:id",
                name: "checkRoomDetails",
                meta: {
                    title: "验房详情",
                },
                props: (route) => ({
                    id: +route.params.id,
                }),
                component: loadView("mobileHomeInspection/homeInspectionDetails"),
            },
            ...SecurityCameraRoutes,
            {
                path: 'data',
                component: loadView('BigData/BigDataPage'),
            },
            {
                path: 'foreman',
                name: 'setForemanLog',
                meta: {
                    title: '领班设置记录'
                },
                component: loadView('SetForemanLog')
            },
            {
                path: 'access/card',
                name: 'accessCard',
                meta: {
                    title: '门卡管理'
                },
                component: loadView('AccessCard/AccessCardManage')
            },
            {
                path: 'menu/program',
                name: 'MiniProgramMenu',
                meta: {
                    title: '小程序功能区域设置'
                },
                component: loadView('MiniProgramMenu/MiniProgramMenu')
            },
            ...ParkRoutes,
            {
                path: "SmartPark",
                name: "SmartPark",
                component: loadView("SmartPark/Smart"),
            },
            {
                path: "RoamingIndoor",
                name: "RoamingIndoor",
                component: loadView("SmartPark/RoamingIndoor"),
            },
            {
                path: "insideBuilding",
                name: "InsideBuilding",
                component: loadView("SmartPark/InsideBuilding2"),
            },
            {
                path: "InsideBuilding7",
                name: "InsideBuilding7",
                component: loadView("SmartPark/InsideBuilding7"),
            },
            {
                path: "PolicyManagement",
                name: "PolicyManagement",
                component: loadView("PolicyManagement/PolicyManagement")
            },
            {
                path: "rLive",
                name: "livePlayer",
                component: loadView("livePlayer/LivePlayer")
            },
            ...DroneRoutes,
            ...RobotRoutes,
            {
                path: "buildingSceneHai",
                name: 'buildingSceneHai',
                meta: {
                    title: '海口大楼'
                },
                component: loadView("BuildingScene/BuildingSceneIndex")
            },
            ...OnlyOnePageRoutes,
            {
                path: "guidance",
                name: 'guidance',
                meta: {
                    title: '办事指南'
                },
                component: loadView("OperationsManagement/Guidance")
            },
            {
                path: "datafile",
                name: 'datafile',
                meta: {
                    title: '资料文件'
                },
                component: loadView("OperationsManagement/DataFile")
            },
            {
                path: 'parkInformation',
                name: 'ParkInformation',
                meta: {
                    title: '园区资讯'
                },
                component: loadView("ParkInformation/ParkInformation")
            },
            //小程员首页轮播图
            {
                path: 'displayDiagram',
                name: 'displayDiagram',

                component: loadView("DisplayDiagram")
            },
            //身份证绑定解绑
            {
                path: 'identityBinding',
                name: 'identityBinding',

                component: loadView("IdentityBinding/IdentityBinding")
            },
            //特权管理
            {
                path: 'privilege',
                name: 'privilege',

                component: loadView("Vip/Privilege")
            }
        ],
    },
    {
        path: "*",
        name: "errorPage",
        component: loadView("ErrorPage"),
    },
];

export default routes;
