import {loadView} from "@/router/loadFn";

export default {
    path: "enterpriseService",
    component: loadView("enterpriseService/enterpriseServiceManage"),
    children: [
        {
            path: "",
            component: loadView("enterpriseService/enterpriseServiceOrder"),
            children: [
                {
                    name: "enterpriseServiceInquiry",
                    path: "",
                    component: loadView(
                        "enterpriseService/enterpriseServiceInquiry"
                    ),
                },
                {
                    name: "enterpriseServiceHasSigning",
                    path: "hasSigning",
                    component: loadView(
                        "enterpriseService/enterpriseServiceHasSigning"
                    ),
                },
                {
                    name: "enterpriseServiceNoSigning",
                    path: "NoSigning",
                    component: loadView(
                        "enterpriseService/enterpriseServiceNoSigning"
                    ),
                },
                {
                    name: "enterpriseServiceHasRefund",
                    path: "HasRefund",
                    component: loadView(
                        "enterpriseService/enterpriseServiceHasRefund"
                    ),
                },
            ],
        },
        {
            path: "items",
            name: "enterpriseServiceItems",
            component: loadView("enterpriseService/enterpriseServiceItems"),
        },
    ],
}
