//获取已保村的楼书信息
const getBuildingBookUrl = "/lease/buildingBookSetting/getBuildingBook";
//个人楼书的保存信息接口
const settingBuildingBookUrl = "/lease/buildingBookSetting/settingBuildingBook";

//查询指定用户一段时间内的 uvPv量
const queryCountUvPvByUserUrl = "/lease/buildingBookSetting/queryCountUvPvByUser";
//查询一段时间内的Uv访问记录
const queryUvByUserUrl = "/lease/buildingBookSetting/queryUvByUser";
//查询一段时间内的Pv访问记录
const queryPvByUserUrl = "/lease/buildingBookSetting/queryPvByUser";
//查询区域内招商部门 指定数量
const queryUvPvByRegionUrl = "/lease/buildingBookSetting/queryUvPvByRegion";
//生成电子楼书二维码
const generateQrCodeUrl = "/lease/buildingBookSetting/generateQrCode";

//查询楼书已设置信息接口
const queryBuildingBookWebsiteUrl = "/lease/buildingBookSetting/queryBuildingBookWebsite";
//设置楼书信息接口
const editBuildingBookWebsiteUrl = "/lease/buildingBookSetting/editBuildingBookWebsite";

//获取是否开启身份校验
const queryIdentityConfigUrl = "/account/user/queryIdentityConfig";
//修改身份验证信息
const editIdentityConfigUrl = "/account/user/editIdentityConfig";
//模糊查询所有企业 租客
const searchLesseeByNameUrl = "/lease/lesseeManage/searchLesseeByName";


//获取是否开启身份校验
const getBigDataConfigUrl = "/public/bigDataConfig/getBigDataConfig";
//修改身份验证信息
const editBigDataConfigUrl = "/public/bigDataConfig/editBigDataConfig";


//添加特权用户
const addVipUserUrl = "/account/vipUser/addVipUser";
//查询特权用户
const queryVipUserListUrl = "/account/vipUser/queryVipUserList";
//删除特权用户
const deleteVipUserUrl = "/account/vipUser/deleteVipUser";
//修改特权用户
const editVipUserUrl = "/account/vipUser/editVipUser";

//上传图片检测
const checkImageUrl = "/account/user/checkImage";
//上传图片检测
const checkFaceImageUrl = "/account/user/checkFaceImage";

export {
    getBuildingBookUrl,
    settingBuildingBookUrl,
    queryCountUvPvByUserUrl,
    queryUvByUserUrl,
    queryPvByUserUrl,
    queryUvPvByRegionUrl,
    generateQrCodeUrl,
    queryBuildingBookWebsiteUrl,
    editBuildingBookWebsiteUrl,
    queryIdentityConfigUrl,
    editIdentityConfigUrl,
    getBigDataConfigUrl,
    editBigDataConfigUrl,
    searchLesseeByNameUrl,
    addVipUserUrl,
    queryVipUserListUrl,
    deleteVipUserUrl,
    editVipUserUrl,
    checkImageUrl,
    checkFaceImageUrl,
}
