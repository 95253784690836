<template>
    <div>
        <nav
            role="navigation" class="navbar-default navbar-static-side"
            id="menu-nav" ref="nav"
        >
            <div class="sidebar-collapse">
                <ul class="nav metismenu" id="side-menu" :style="{'--nav-second-position-top': navSecondPositionTop + 'px'}">
                    <li class="nav-header nav-header-top" v-on:click="_gotoIndex()">
                        <div class="profile-element">
                            <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-menua-lianhe4" class="icon-font"></use>
                            </svg>
                            <!-- <img style="width: 200px" vue-router-keep-alive-helper="@/assets/zhikelogo.png" alt="" /> -->
                        </div>
                        <div class="logo-element">
                            <svg class="icon" aria-hidden="true" style="height: 74px;font-size: 48px;">
                                <use xlink:href="#icon-menua-logo2" class="icon-font"></use>
                            </svg>
                        </div>
                    </li>
                    <li
                        v-for="(menu, index) in menus" @click="switchMenu(menu.id, $event)"
                        v-bind:class="{ active: menu.id === checkedMenu }"
                        :key="index"
                    >
                        <template
                            v-if="menu.resources != undefined && menu.resources.length > 0"
                        >
                            <a>
                                <svg class="icon" aria-hidden="true">
                                    <use :xlink:href="'#' + menuNameIconMapping[menu.name]"></use>
                                </svg>
                                <!-- <i
                                                    :class="[
                                                        'iconfont-menu',
                                                        menuNameIconMapping[menu.name],
                                                    ]"
                                                ></i> -->
                                <!-- <i class="fa" v-bind:class="menu.icon"></i> -->
                                <span class="nav-label" style="position: relative">{{ menu.name }}
                                      <span class="dot"
                                            v-if="menuMapping.get(menu.id).showDot"
                                      >
                                      </span>
                                </span>
                                <span class="fa arrow"></span>
                            </a>
                            <ul
                                v-if="menu.resources != undefined && menu.resources.length > 0"
                                class="nav nav-second-level collapse" @click.stop
                                v-bind:class="{ in: menu.id === checkedMenu }"
                            >
                                <template v-for="(subMenu, subIndex) in menu.resources">
                                    <li :key="subIndex + 'sub'">
                                        <a @click="navigationListener(subMenu.path, menu.id)">
                                          <span
                                              :style="{
                                              position: 'relative',
                                              color: setColor(subMenu.path),
                                            }"
                                          >
                                            {{ subMenu.name }}
                                              <!-- <span
                                                                      v-if="subMenu.tips"
                                                                      :style="badgeCircle"
                                                                  ></span> -->
                                          </span>
                                            <span
                                                class="dot"
                                                v-if="menuMapping.get(subMenu.id).showDot"
                                            ></span>
                                        </a>
                                    </li>
                                </template>
                            </ul>
                        </template>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</template>
<script>
import {getMenuUrl} from "@/requestUrl";
import {STORAGE_KEY, THEMENU_ID} from "@/constant";

export default {
    props: {},
    components: {},
    data() {
        return {
            navSecondPositionTop: 100,
            // 菜单和图标的映射
            menuNameIconMapping: {
                组织权限: "icon-menubianzu",
                业户信息: "icon-menuyehuguanli",
                园区管理: "icon-menuguanliquguanli",
                个人服务: "icon-menugerenfuwu",
                租赁管理: "icon-menuzulinguanli",
                企业服务: "icon-menuqiyefuwu",
                资产管理: "icon-menuzichanguanli",
                考核管理: "icon-menukaoheguanli",
                设备管理: "icon-menushebeiguanli",
                考勤管理: "icon-menukaoqinguanli",
                会议室: "icon-menuhuiyishiguanli",
                巡检管理: "icon-menuxunjianguanli",
                客服中心: "icon-menukefuzhongxin",
                闪购管理: "icon-menudianshangguanli",
                运营管理: "icon-menuyunyingguanli",
                台账管理: "icon-menutaizhang",
                数据统计: "icon-menushujutongji",
                招商管理: "icon-menuzhaoshangguanli",
                责任区管理: "icon-menuzerenqu",
                资料管理: "icon-menuziliaoguanli",
                品质管理: "icon-menupinzhiguanli",
                排班考勤: "icon-menupaibankaoqin",
                库存管理: "icon-menukucunguanli",
                智能抄表: "icon-menubianzu2",
                位置管理: "icon-menuweizhiguanli",
                验房管理: "icon-menuyanfangguanli",
                智慧园区: "icon-menua-zu2",
                门卡管理: "icon-menua-zu1",
                监控管理: "icon-menua-fenzu2",
                政策计算器: "icon-menuzhengce",
                停车管理: "icon-menutingche",
                水电表管理: "icon-menubianzu2",
                环境监测: "icon-menuhuanjing",
                无人机管理: "icon-menua-lianhe1",
                智能机器人:'icon-menujiqiren',
                企业个人管理:'icon-menuqiyegerenguanli',
                装修管理:'icon-menuzhuangxiu'
            },
            badgeCircle: {
                background: "red",
                "border-radius": "50%",
                width: "8px",
                height: "8px",
                display: "inline-block",
                position: "absolute",
                top: 0,
                right: "-4px",
            },
            menus: [],
            checkedMenu: null,
            menuMapping: {},
            logo: "",
            socket: {},
        };
    },
    created() {
        this.checkedMenu = parseInt(sessionStorage.getItem(THEMENU_ID));
        if(this.$route.name == 'shortNoteBill'){
            return
        }
        this.getMenuList();
    },
    mounted() {
        this.$vc.on(this.$route.path, "dot", "menu", (params) => {
            if (this.menuMapping instanceof Map) {
                const redDotMapping = {
                    // 客服中心: ["工单账单新付款", "工单账单新退款"],
                    // 工单账单: ["工单账单新付款", "工单账单新退款"],
                    社区管理: ["社区管理新敏感字图"],
                    运营管理: ["社区管理新敏感字图"],
                    /*企业服务: [rr
                                  "企业服务管理询价",
                                  "企业服务账单新付款",
                                  "企业服务账单新退款",
                              ],
                              企业服务管理: ["企业服务管理"],
                              企业服务账单: ["企业服务账单新退款", "企业服务账单新付款"],*/
                };

                this.menuMapping.forEach((val, key) => {
                    if (
                        redDotMapping[val.name] &&
                        redDotMapping[val.name].includes(params.typeStr)
                    ) {
                        this.menuMapping.set(key, {
                            ...val,
                            showDot: params.isShow,
                        });
                    }
                });
                this.$forceUpdate();
            } else {
                console.log("设置菜单的红点失败");
            }
        });
    },
    updated() {

    },
    methods: {
        // 获取菜单列表
        getMenuList() {
            const menuList = JSON.parse(
                sessionStorage.getItem(STORAGE_KEY.MENU_LIST) || "[]"
            );
            if (menuList.length > 0) {
                this.menus = menuList;
                this.setMenuMappding(menuList);
                return;
            }
            this.$fly.get(getMenuUrl).then((res) => {
                if (res.code != 0) {
                    return;
                }
                sessionStorage.setItem(
                    STORAGE_KEY.MENU_LIST,
                    JSON.stringify(res?.data || [])
                );
                this.setMenuMappding(res?.data || []);
                this.menus = res?.data || [];
            });
            console.log("???");
        },
        setMenuMappding(menuList) {
            let menuMapping = new Map();
            menuList.forEach((menu) => {
                if (menu.resources && menu.resources.length > 0) {
                    menu.resources.forEach((subMenu) => {
                        menuMapping.set(subMenu.id, {
                            ...subMenu,
                            showDot: false,
                        });
                    });
                }
                if (menu.parentId === 0) {
                    menuMapping.set(menu.id, {
                        ...menu,
                        resources: null,
                        showDot: false,
                    });
                }
            });
            this.menuMapping = menuMapping;
        },
        _gotoIndex: function () {
            sessionStorage.removeItem(STORAGE_KEY.ROUTE_HISTORY);
            this.$vc.jumpToPage("/flow/index");
        },
        switchMenu: function (id, e) {
            //设置菜单ID
            if (this.checkedMenu == id) {
                this.checkedMenu = null;
            } else {
                this.checkedMenu = id;
            }
            const {top: navSecondPositionTop} = e.currentTarget.getBoundingClientRect(),
                   // 下行用到的38是子菜单项li的高度 20是子菜单的内边距
                   navSecondLevelHeight = Array.prototype.reduce.call(e.currentTarget.querySelector('.nav-second-level').children, acc => acc + 38, 0) + 20,
                    windowHeight = document.body.clientHeight;
            if (navSecondPositionTop + navSecondLevelHeight > windowHeight) {
                this.navSecondPositionTop = windowHeight - navSecondLevelHeight;
            } else {
                this.navSecondPositionTop = navSecondPositionTop;
            }
        },
        /**
         * url跳转监听
         * @param {Object} path 菜单路径
         * */
        navigationListener(path, id) {
            console.log(path);
            if(path == '/pushNull'){
                this.$vc.toast("权限未开放，请联系管理员");
                return
            }
            sessionStorage.removeItem(STORAGE_KEY.OPERATIONAL_QUERY_PARAMS);
            sessionStorage.removeItem(STORAGE_KEY.ROUTE_HISTORY);
            sessionStorage.setItem(THEMENU_ID, id);
            this.$router.push({path});
        },
        setColor(path) {
            if (path.slice(0, path.lastIndexOf('/')) === '/flow/meetingRoom' && this.$route.path.slice(0, this.$route.path.lastIndexOf('/')) === '/flow/meetingRoom') {
                return 'rgb(26, 179, 148)';
            }
            return this.$route.path.indexOf(path) ? '#f0f0f0' : 'rgb(26, 179, 148)';
        },
    },
};
</script>
<style lang="stylus">
@import '../style/menuIcon.styl';

#menu-nav {
    height: 100vh;
    overflow-y: auto;
    width: 180px;
    //display: inline-block;
    vertical-align: top;

    .nav-header {
        font-size: 20px;
        txt-align: center;
        color: #fff;
        padding: 11px 10px 4px;

        .profile-element {
            //margin-top: -11px;

            .icon {
                width: 100%;
                height: 70px;
            }
        }
    }

    .nav > li {
        overflow: visible;

        & > a {
            color: #676a6c;

            &:hover {
                color: #fff;
            }

            svg {
                margin-right: 6px;
                font-size: 16px;
            }
        }
    }

    .nav-second-level, .nav-label {
        a {
            position: relative;

            &:hover {
                span {
                    color: rgb(26, 179, 148) !important;
                }
            }
        }

        .dot {
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #ff0000;
            transform: translateX(-40%) translateY(-20%);
        }
    }
}

.sidebar-collapse {
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
}
.fa{
    color: #f0f0f0
}
/*#menu-nav {
    -ms-overflow-style: none;
    scrollbar-width: none

    &::-webkit-scrollbar {
        display: none;
    }
}*/

.mini-navbar {
    #menu-nav {
        width: 70px;
        //overflow: visible;
        /*padding-right: 170px;
        margin-right: -170px;*/
        overflow-x: hidden;
        overflow-y: auto;

        .nav-second-level {
            a {
                color: #fff !important;

                span {
                    display: inline-block;
                    color: #fff !important;
                }
            }
        }

        #side-menu > li {
            width: 70px;

            & > a {
                width: 70px;
            }
        }

        .nav-second-level {
            position: fixed;
            left: 60px;
            top: var(--nav-second-position-top, 100px);
            z-index: 2010;
        }
        .nav-second-level li:last-child {
            padding-bottom: 0;
        }
    }


}

img {
    image-rendering: pixelated;
}
@media screen and (max-height: 965px) {
    .nav-header-top {
        padding: 0 0 4px 0 !important;
    }
    .profile-element{
        margin:0 auto;
        width: 92%;
    }
}
 a:hover .nav-label{
    color: rgb(26, 179, 148) !important
}
a:hover .fa{
    color: rgb(26, 179, 148) !important
}
</style>

