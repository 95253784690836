import {loadView} from "@/router/loadFn";

export default [
    {
        path: 'park/pay/records',
        name: 'parkingPayment',
        meta: {
            title: '缴费记录'
        },
        component: loadView('ParkingLot/PaymentRecords')
    },
    {
        path: 'park/records',
        name: 'parkRecords',
        meta: {
            title: '车辆进出记录'
        },
        component: loadView('ParkingLot/ParkRecords')
    },
    {
        path: 'park/licensePlate',
        name: 'licensePlate',
        meta: {
            title: '车辆绑定管理'
        },
        component: loadView('ParkingLot/LicensePlate')
    },
    {
        path: 'park/coupons',
        name: 'parkCoupons',
        meta: {
            title: '停车券管理'
        },
        component: loadView('ParkingLot/ParkCoupons')
    },
    {
        path: 'park/venueCar',
        name: 'VenueCar',
        meta: {
            title: '场内车辆'
        },
        component: loadView('ParkingLot/VenueCar')
    },
    {
        path: 'park/coupons/:id/log',
        name: 'parkCouponsLog',
        meta: {
            title: '已领人次'
        },
        props: route => {
            return {
                id: +route.params.id
            }
        },
        component: loadView('ParkingLot/ParkCouponsLog')
    },
]
