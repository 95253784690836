//  storage存储的统一管理
const STORAGE_KEY = {
  X_TOKEN: "x-token",
  USER_INFO: "userInfo",
  CURRENT_REGION: "currentRegion",
  MENU_LIST: "menuList",
  REGION_LIST: "regionList",
  ACCOUNT: "account",
  //  没有登录状态时所在的页面
  BEFORE_LOGIN_PAGE: "beforeLoginPage",
  OPERATIONAL_QUERY_PARAMS: "operationalQueryParams",
  // 路由历史记录
  ROUTE_HISTORY: "routeHistory",
  // 库存管理出入库退回上级页面tabBar记录
  WAREHOUSE_TABID: "warehouseTabBar",
  // 物业数据下级页面的数据
  OPERATIONAL_NEXT_PAGE_DATA: "OPERATIONAL_NEXT_PAGE_DATA",
  // 合同附件
  CONTRACT_ATTACHMENTS: "CONTRACT_ATTACHMENTS"
};

export default STORAGE_KEY;
