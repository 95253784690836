import {loadView} from "@/router/loadFn";

export default [
    {
        path: "dronesManage",
        name: "drones",
        component: loadView("Drones/DronesManage"),
    },
    {
        path: "dronesMission",
        name: "flightMission",
        component: loadView("Drones/FlightMission"),
        meta: {
            title: '飞行任务',
        }
    },
    {
        path: "dronesMission/add",
        name: "addFlightMission",
        meta: {
            title: '添加航点飞行任务'
        },
        component: loadView("Drones/AddFlightMission")
    },
    {
        path: "dronesMission/edit/:flight/:id/:back",
        name: "editFlightMission",
        props: route => {
            const {id, flight, back} = route.params,
                {name: defaultName, points} = route.query;
            return {
                id: +id,
                defaultName,
                flight: +flight,
                back: +back,
                defaultWayPoints: points ? JSON.parse(points || '[]').map(point => point.split(',')) : []
            }
        },
        meta: {
            title: '修改航点飞行任务'
        },
        component: loadView("Drones/AddFlightMission")
    },
    {
        path: "dronesFlightLog",
        name: "flightRecord",
        component: loadView("Drones/FlightRecord")
    },
    {
        path: "wifiConfig",
        name: "wifiConfig",
        component: loadView("Drones/DroneWifiConfig")
    }
]
