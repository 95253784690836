import { loadView } from "@/router/loadFn";

//机器人路由
export default [{
        path: 'RobotManagement',
        name: 'RobotManagement',
        component: loadView("IntelligentRobot/RobotManagement")
    },
    {
        path: "patrolRecord",
        name: "patrolRecord",
        meta: {
            title: '巡视记录'
        },
        component: loadView("IntelligentRobot/patrolRecord")
    },
    {
        path: "patrolTaskManagement",
        name: "patrolTaskManagement",
        meta: {
            title: '巡视任务管理'
        },
        component: loadView("IntelligentRobot/patrolTaskManagement")
    },
    {
        path: "patrolTaskManagement/add",
        name: "addPatrolTask",
        meta: {
            title: '创建巡视任务'
        },
        component: loadView("IntelligentRobot/addPatrolTask")
    },
    {
        path: "patrolTaskManagement/update",
        name: "updatePatrolTask",
        meta: {
            title: '修改巡视任务'
        },
        component: loadView("IntelligentRobot/updatePatrolTask")
    }
]