//查询室外气象信息列表
const outdoorWeatherUrl = '/meter/outdoor/queryOutdoorInfo';
//查询室内气象信息列表
const envIndoorUrl = ' /meter/indoor/queryIndoorInfo';
//获取已设置 数据
const envScheduledTaskUrl = '/meter/scheduledTask/taskRegionCode';
//设置获取信息时间
const setScheduledTaskUrl = '/meter/scheduledTask/start';
//查询设备列表接口
const selectQueryEquipmentUrl = '/meter/equipment/queryEquipment';
//添加设备接口
const addEquipmentUrl = '/meter/equipment/addEquipment';
//查询位置
const envQueryLocationUrl = '/inspection/location/querySimilarLocation';
//查询时间列表
const envQueryTimeUrl = '/meter/scheduledTask/taskList';
//修改设备接口
const envEditEquipmentUrl = '/meter/equipment/editEquipment';
//删除设备接口
const envDeleteEquipmentUrl = '/meter/equipment/deleteEquipment';
//设置指定人员通行电梯
const addStaffElevatorUrl = '/turnstile/staff/elevator/addStaffElevator'
//查询指定人员通行电梯
const queryAllSettingUrl = '/turnstile/staff/elevator/queryAllSetting';

//获取楼层乘梯二维码
const getElevatorQrCodeUrl = '/turnstile/user/elevator/getElevatorQrCode';

//查询指定人员通行电梯
const ueryAllSettingUrl = '/turnstile/elevatorConfig/queryElevatorConfig';
//删除
const deleteElevatorConfigUrl = '/turnstile/elevatorConfig/deleteElevatorConfig';
//修改
const editElevatorConfigUrl = '/turnstile/elevatorConfig/editElevatorConfig';
//添加
const addElevatorConfigUrl = '/turnstile/elevatorConfig/addElevatorConfig';
//根据手机号查询信息
const queryBuildingUserUrl = '/account/user/queryBuildingUser';
// 查询访客申请设置
const queryVisitSettingUrl = '/turnstile/visit/setting/queryVisitSetting';
// 设置访客通行设置
const settingVisitSettingUrl = '/turnstile/visit/setting/visitSetting';

export {
    outdoorWeatherUrl,
    envIndoorUrl,
    envScheduledTaskUrl,
    setScheduledTaskUrl,
    selectQueryEquipmentUrl,
    addEquipmentUrl,
    envQueryLocationUrl,
    envQueryTimeUrl,
    envEditEquipmentUrl,
    envDeleteEquipmentUrl,
    addStaffElevatorUrl,
    queryAllSettingUrl,
    getElevatorQrCodeUrl,
    ueryAllSettingUrl,
    deleteElevatorConfigUrl,
    addElevatorConfigUrl,
    queryBuildingUserUrl,
    editElevatorConfigUrl,
    queryVisitSettingUrl,
    settingVisitSettingUrl
}
