import "../public/common/static/js/jquery.step.min.js";
import "../public/common/static/js/bootstrap/bootstrap.js";
import "./assets/js/icon.js";
// 按需引入elementui的组件
import "element-ui/lib/theme-chalk/index.css";
// import element from "./element";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vc from "../common/core";
import {fly} from "./requestUrl";
import {BUCKET_NAMES, uploadFileOne} from "./utils/Constants";
import * as echarts from "echarts";
import CSDialog from "./plugins/dialog.js";
import {add, div, mul, numberMul, sub} from "./utils/numberUtil.js";
// 全局引入elementUI的样式
import ElementUI from 'element-ui';
import "./style/elTheme.scss";
import "./style/common.styl";
import "../public/common/static/js/plugins/xss/xss.min.js";
import "../public/common/static/js/bootstrap/jquery-3.3.1.min.js";


Vue.use(ElementUI);
Vue.use(CSDialog);
Vue.config.productionTip = false;
Vue.prototype.$vc = vc;
Vue.prototype.$fly = fly;
Vue.prototype.Constants = {BUCKET_NAMES, uploadFileOne};
Vue.prototype.$echarts = echarts;
Vue.prototype.numberUtil = {numberMul, div, sub, add, mul};

/* window.$ = $;
window.jQuery = $; */

var vueDom = new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");
export default vueDom;
