import STORAGE_KEY from "./storageKey";

//  支付渠道
const PAY_CHANNEL = {
    WE_CHAT_PAY: 0,
    ALI_PAY: 1,
    CASH_PAY: 2,
    BANK_PAY: 3,
};
// 支付渠道
const PAY_CHANNEL_MAPPING = {
    [PAY_CHANNEL.WE_CHAT_PAY]: "微信",
    [PAY_CHANNEL.ALI_PAY]: "支付宝",
    [PAY_CHANNEL.CASH_PAY]: "现金支付",
    [PAY_CHANNEL.BANK_PAY]: "银行转账",
};
//发票状态
const INVOICE_STATUS = {
    NOT_OPEN: 0, //未开
    ON_OPEN: 1
}
//发票状态
const INVOICE_STATUS_NAME = {
    [INVOICE_STATUS.NOT_OPEN]: '未开',
    [INVOICE_STATUS.ON_OPEN]: '已开',
}
const HYDROPOWER = {
    WATER_METER: 1, //水表
    WATT_HOUR_METER: 2 //电表
};
const QUESTIONNAIRE_SEND_TYPE = {
    //  楼内用户
    USER: 1,
    // 访客
    VISITER: 2,
    // 企业对接人
    ENTERPRISE_DOCKING_PERSON: 3,
    // 业主
    OWNER: 4,
    // 普通员工（物业员工）
    EMPLOYEE: 5,
    // 部门主管（物业员工）
    DEPARTMENT_MANAGER: 6,
    // 物业经理（物业员工）
    PROPERTY_MANAGER: 7,
};

const QUESTIONNAIRE_SEND_TYPE_STR = {
    [QUESTIONNAIRE_SEND_TYPE.USER]: "楼内用户",
    [QUESTIONNAIRE_SEND_TYPE.VISITER]: "访客（含未知身份）",
    [QUESTIONNAIRE_SEND_TYPE.ENTERPRISE_DOCKING_PERSON]: "企业对接人",
    [QUESTIONNAIRE_SEND_TYPE.OWNER]: "业主",
    [QUESTIONNAIRE_SEND_TYPE.EMPLOYEE]: "普通员工",
    [QUESTIONNAIRE_SEND_TYPE.DEPARTMENT_MANAGER]: "部门主管",
    [QUESTIONNAIRE_SEND_TYPE.PROPERTY_MANAGER]: "物业经理",
};
// 触发问卷发送的类型
const TRIGGER_SEND_QUESTIONNAIRE_TYPE = {
    // 行为
    DEED: 1,
    // 定时发送
    TIMING: 2,
    // 注册时间发送
    REGISTER_DATE: 3,
    // 合同签约时间发送
    SIGN_CONTRACT_DATE: 4,
};

//右侧菜单ID键
const THEMENU_ID = "theMenuId";
// 问题类型
const QUESTION_TYPE = {
    // 单选
    RADIO: 1,
    // 多选
    MULTIPLE_SELECTION: 2,
    // 填空题
    FILL_BLANK: 3,
};

// 组织等级
const ORG_LEVEL = {
    // 公司
    COMPANY: 0,
    // 部门
    DEPARTMENT: 1,
    // 小组
    GROUP: 2,
};

// 职级
const JOB_LEVEL = {
    // 部门主管
    SUPERVISOR: 1,
    // 小组主管
    TEAM_SUPERVISOR: 2,
    // 员工
    STAFF: 4,
    // 实习生
    TRAINEE: 8,
};
//添加合同所选的业主还是租客
const ROOM_TEMPORARY_RESIDENT = {
    //租客
    TENANT: 1,
    //业主
    OWNER: 2
};
const DEPARTMENT_TYPES = {
    // 总监办公室
    DIRECTOR: 1001,
    // 经理办公室
    MANAGER: 1002,
    // 工程部
    ENGINEERING: 1003,
    // 客服部
    CUSTOMER_SERVICE: 1004,
    // 保洁部
    PROCTER_GAMBLE: 1005,
    // 保安部
    SECURITY: 1006,
    // 人事部
    PERSONNEL: 1007,
    // 财务部
    FINANCIAL: 1008,
    // 运营部
    OPERATIONS: 1009,
    // 招商部
    INVESTMENT_PROMOTION: 1010,
    // 品质管理
    QUALITY: 1011,
    // 总裁办
    PRESIDENT_OFFICE: 1012,
    // 行政部
    ADMINISTRATION_DEPARTMENT: 1013,
    //园区运营部
    PARK_OPERATION_DEPARTMENT: 1014
};

const DEPARTMENT_TYPE_STR = {
    [DEPARTMENT_TYPES.DIRECTOR]: '总监办公室',
    [DEPARTMENT_TYPES.MANAGER]: '经理办公室',
    [DEPARTMENT_TYPES.ENGINEERING]: '工程部',
    [DEPARTMENT_TYPES.CUSTOMER_SERVICE]: '客服部',
    [DEPARTMENT_TYPES.PROCTER_GAMBLE]: '保洁部',
    [DEPARTMENT_TYPES.SECURITY]: '保安部',
    [DEPARTMENT_TYPES.PERSONNEL]: '人事部',
    [DEPARTMENT_TYPES.FINANCIAL]: '财务部',
    [DEPARTMENT_TYPES.OPERATIONS]: '运营部',
    [DEPARTMENT_TYPES.INVESTMENT_PROMOTION]: '招商部',
    [DEPARTMENT_TYPES.QUALITY]: '品质管理部',
    [DEPARTMENT_TYPES.PRESIDENT_OFFICE]: '总裁办',
    [DEPARTMENT_TYPES.ADMINISTRATION_DEPARTMENT]: '行政部',
    [DEPARTMENT_TYPES.PARK_OPERATION_DEPARTMENT]: '园区运营部'
};

// 行业类型
const INDUSTRY_TYPES = {
    1: "电商",
    2: "游戏",
    3: "媒体",
    4: "广告营销",
    5: "数据服务",
    6: "医药医疗",
    7: "生活服务",
    8: "O2O",
    9: "旅游",
    10: "分类信息",
    11: "音乐/视频/阅读",
    12: "教育培训",
    13: "社交网络",
    14: "人力资源服务",
    15: "企业服务",
    16: "信息安全",
    17: "智能硬件",
    18: "移动互联网",
    19: "互联网",
    20: "计算机软件",
    21: "通信/网络设备",
    22: "广告/公告/会展",
    23: "互联网金融",
    24: "物流/仓储",
    25: "贸易/进出口",
    26: "咨询",
    27: "工程",
    28: "汽车生产",
    29: "建筑/建材",
    30: "农林牧鱼",
    31: "耐用消费品",
    32: "出版/印刷/造纸",
    33: "批发零售",
    34: "生物/制药",
    35: "家具/室内设计/装潢",
    36: "电子技术/半导体/集成电路",
    37: "娱乐休闲/餐饮",
    38: "法律/法务",
    39: "其它",
    40:'商业配套',
    41:'检验检测',
    42:'生产加工',
    43:'科技行业'
};
// 企业类型
const CUSTOMER_SOURCES = {
    1: "自然来访",
    2: "中介提供",
    3: "网络收集",
    4: "转介拓客",
    5: '网站收集',
    6: '电子楼书',
    7:'自然来电',
    8:'电话微信拓客',
    9:'拜访拓客',
    10:'代理公司'
};
////新加  认知途径 1:推荐;2:路过;3:大屏电话;4:微信电话;5:陌拜
const CONITIVE_PATHWAY_NEW = {
    1:'推荐',
    2:'路过',
    3:'大屏电话',
    4:'微信电话',
    5:'陌拜'
}
//选新加 选址目的
const PURPOSE_OFSITE_SELECTION = {
    1:'办公需求',
    2:'生产需求',
    3:'仓库需求',
    4:'商业配套需求'
}
//机器人状态
const ROBOT_STATE = {
    0: '空闲状态',
    1: '运行中',
    2: '对接充电桩',
    3: '充电中',
    4: '重定位中',
    5: '系统错误',
    6: '暂停任务',
    7: '退出充电桩',
    8: '正在进行建图'
}
export {
    ROBOT_STATE,
    PAY_CHANNEL,
    PAY_CHANNEL_MAPPING,
    QUESTIONNAIRE_SEND_TYPE,
    QUESTIONNAIRE_SEND_TYPE_STR,
    TRIGGER_SEND_QUESTIONNAIRE_TYPE,
    QUESTION_TYPE,
    STORAGE_KEY,
    ORG_LEVEL,
    JOB_LEVEL,
    DEPARTMENT_TYPES,
    INDUSTRY_TYPES,
    CUSTOMER_SOURCES,
    DEPARTMENT_TYPE_STR,
    THEMENU_ID,
    HYDROPOWER,
    INVOICE_STATUS_NAME,
    ROOM_TEMPORARY_RESIDENT,
    CONITIVE_PATHWAY_NEW,
    PURPOSE_OFSITE_SELECTION
};
