import {loadComponent, loadView} from "@/router/loadFn";

export default [
    {
        path: "securityCamera/cameraManage",
        name: "camareManage",
        component: loadView("SecurityCamera/cameraManage"),
        meta: {
            title: "监控",
        }
    },
    {
        path: "securityCamera/abnormalManage",
        name: "abnormalManage",
        meta: {
            title: '异常抓拍与工单推送'
        },
        component: loadView("SecurityCamera/abnormalManage")
    },
    {
        path: "securityCamera/faceCapture",
        name: "faceCapture",
        meta: {
            // title: '抓拍记录与关注人'
            title:'删除人脸数据记录'
        },
        component: loadView("SecurityCamera/faceCapture")
    },
    {
        path: "securityCamera/boxManagement",
        name: "boxManagement",
        meta: {
            title: '盒子管理'
        },
        component: loadView("SecurityCamera/boxManagement")
    },
    {
        path: "securityCamera/peopleCensus",
        name: "peopleCensus",
        component: loadView("SecurityCamera/peopleCensus")
    },
    {
        path: "securityCamera/cameraVideo",
        name: "cameraVideo",
        meta: {
            title: "监控录像"
        },
        component: loadView("SecurityCamera/cameraVideo")
    },
    {
        path: `securityCamera/cameraVideoDetail/:id`,
        name: "cameraVideoDetail",
        props: (route) => ({
            id: +route.params.id,
        }),
        meta: {
            title: "录像"
        },
        component: loadComponent("SecurityCamera/videoSecondDetail"),
    },
    {
        path: `securityCamera/cameraClubDetail/:id`,
        name: "cameraClubDetail",
        props: (route) => ({
            id: +route.params.id,
        }),
        meta: {
            title: "监控"
        },
        component: loadComponent("SecurityCamera/clubSecondDetail"),
    },
    {
        path: `securityCamera/face/diff/:id`,
        name: "captureFaceDiffLog",
        props: (route) => ({
            id: +route.params.id,
        }),
        meta: {
            title: "抓图情况"
        },
        component: loadView("SecurityCamera/CaptureFaceDiffLog"),
    },
]
