import { loadView } from "@/router/loadFn";

export default {
    path: "meetingRoom",
    component: loadView("MeetingRoom/MeetingRoomManage"),
    children: [{
            name: "meetingRoomManage",
            path: "",
            component: loadView("MeetingRoom/MeetingRoomView"),
        },
        {
            name: "refundMeetingRoom",
            path: "refund",
            component: loadView("MeetingRoom/RefundMeetingRoomCharge"),
        },
        {
            name: "meetingRoomOrder",
            path: "order",
            component: loadView("MeetingRoom/MeetingRoomOrder"),
        },
        {
            name: "meetingRoomConfig",
            path: "config",
            component: loadView("MeetingRoom/MeetingRoomConfig"),
        },
    ],
}