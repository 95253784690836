//查询待审批列表
const queryApprovalPending = "/lease/decorationProcess/queryApprovalPending";
//查询待审批列表
const queryApprovedDrawing = "/lease/decorationProcess/queryApprovedDrawing";
//查询装修流程详情
const queryDeclareDetails = "/lease/decorationProcess/queryDeclareDetails";
//同意/拒绝审批
const changeResults = "/lease/decorationProcess/changeResults";
//查询已审批人列表
const queryDeclarantList = "/lease/decorationProcess/queryDeclarantList";
//查询装修申请信息
const queryDecorateDetail = "/lease/decorationProcess/queryDecorateDetail";


//添加检查项
const addDecorationItem = "/lease/decorationItem/addDecorationItem";
//查询检查项
const queryDecorationItem = "/lease/decorationItem/queryDecorationItem";
//删除检查项
const deleteDecorationItem = "/lease/decorationItem/deleteDecorationItem";

//查询审批人
const queryApprover = "/lease/approver/queryApprover";
//设置审批人
const setApprover = "/lease/approver/setApprover";
//查询当前用户是否是审批人

const isApprovalUrl = "/lease//approver/isApproval";
export {
    queryApprovalPending,
    queryApprovedDrawing,
    queryDeclareDetails,
    changeResults,
    addDecorationItem,
    queryDecorationItem,
    deleteDecorationItem,
    queryApprover,
    setApprover,
    queryDeclarantList,
    queryDecorateDetail,
    isApprovalUrl,
}
