// 机器人设备 API
// 添加机器人
const addRobotDeviceUrl = "/robot/robotdevice/addRobotDevice";
// 删除机器人
const deleteRoboDeviceByIdUrl = "/robot/robotdevice/deleteRoboDeviceById";
// 修改机器人设备
const modifyRobotDeviceByIdUrl = "/robot/robotdevice/modifyRobotDeviceById";
// 查询所有机器人设备
const queryRobotDeviceUrl = "/robot/robotdevice/queryRobotDevice";
// 查询单个机器人设备
const queryRobotDeviceByIdUrl = "/robot/robotdevice/queryRobotDeviceById";
//机器人范航
const terminationReturnUrl = '/robot/robotdevice/terminationReturn';
// 巡视任务API
// 添加巡视任务
const addPatrolTaskUrl = "/robot/patroltask/addPatrolTask";
// 删除巡视任务
const deletePatrolTaskUrl = "/robot/patroltask/deletePatrolTask";
// 修改巡视任务
const modifyPatrolTaskUrl = "/robot/patroltask/modifyPatrolTask";
// 查询所有巡视任务
const queryAllPatrolTaskUrl = "/robot/patroltask/queryAllPatrolTask";
// 查询单个巡视任务
const queryPatrolTaskByIdUrl = "/robot/patroltask/queryPatrolTaskById";

// 巡视任务记录API
// 查询巡视记录列表
const queryPatrolTaskLogByConditionUrl = "/robot/patroltasklog/queryPatrolTaskLogByCondition";

// 机器人地图API
// 删除机器人地图
const deleteRobotPatrolMapUrl = "/robot/robotpatrolmap/deleteRobotPatrolMap";
// 查询机器人地图列表
const queryAllRobotPatrolMapUrl = "/robot/robotpatrolmap/queryAllRobotMap";
//启动机器人
const robotDeviceUrl = '/robot/robotdevice/startAutomaticallyPatrol/';
//机器人控制台以及巡视路线信息
const queryRobotDeviceMapUrl = '/robot/robotdevice/queryRobotDeviceMap';
//机器人近五次任务
const ueryInspectionTasksUrl = `/robot/robotdevice/queryInspectionTasks;`
export {
    addRobotDeviceUrl,
    deleteRoboDeviceByIdUrl,
    modifyRobotDeviceByIdUrl,
    queryRobotDeviceUrl,
    addPatrolTaskUrl,
    deletePatrolTaskUrl,
    modifyPatrolTaskUrl,
    queryAllPatrolTaskUrl,
    queryPatrolTaskByIdUrl,
    queryPatrolTaskLogByConditionUrl,
    deleteRobotPatrolMapUrl,
    queryAllRobotPatrolMapUrl,
    queryRobotDeviceByIdUrl,
    robotDeviceUrl,
    queryRobotDeviceMapUrl,
    ueryInspectionTasksUrl,
    terminationReturnUrl
}