//查询电梯列表
const querAllElevatorUrl = '/turnstile/elevator/querAllElevator';
//添加电梯
const addElevatorUrl = '/turnstile/elevator/addElevator';
//删除电梯
const deleteElevatorUrl = '/turnstile/elevator/deleteElevator';
//修改电梯
const editElevatorUrl = '/turnstile/elevator/editElevator';
export {
    querAllElevatorUrl,
    addElevatorUrl,
    deleteElevatorUrl,
    editElevatorUrl
}