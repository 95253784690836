// 查询供应商接口
const warehouseSupplierUrl = '/inventory/supplier/adminQuery';
// 添加供应商接口
const warehouseAddSupplierUrl = "/inventory/supplier/add";
// 修改供应商状态接口
const warehouseSupplierStateUrl = "/inventory/supplier/changeState";
// 查询供应商接口(下拉框)
const warehouseSupplierDetailUrl = "/inventory/supplier/query";
// 查询单个物品接口
const querySingleUrl = "/inventory/goods/querySingle";

// 查询物品接口
const warehouseItemUrl = '/inventory/goods/adminQuery';
// 添加物品接口
const warehouseAddItemUrl = "/inventory/goods/add";
// 修改物品状态接口
const warehouseStateUrl = "/inventory/goods/changeGoods";

// 下载入库记录接口
const downloadInWarehouseUrl = "/inventory/store/downloadLog"
// 添加入库 接口
const addInWarehouseUrl = "/inventory/store/add";
// 查询入库记录接口
const inWarehouseUrl = "/inventory/store/adminQuery";

// 添加出库记录接口
const addOutWarehouseUrl = "/inventory/deliver/add";
// 下载出库记录接口
const downloadOutWarehouseUrl = "/inventory/deliver/downloadLog";
// 查询出库记录接口
const outWarehouseUrl = "/inventory/deliver/adminQuery";
// 归还出库物品接口
const returnOutWarehouseUrl = "/inventory/deliver/returnGoods";

// 创建盘点任务接口
const addMissonUrl = "/inventory/stocktaking/add";
// 查询盘点任务接口
const missionUrl = "/inventory/stocktaking/adminQuery";
// 查询负责盘点任务接口
const missionDutyUrl = "/inventory/stocktaking/queryDetail";
// 删除盘点任务接口
const deleteMissionUrl = "/inventory/stocktaking/deleteTask";
// 下载盘点任务接口
const downloadMissionUrl = "/inventory/stocktaking/downloadDetail"
export {
    warehouseSupplierUrl,
    warehouseAddSupplierUrl,
    warehouseSupplierStateUrl,
    warehouseSupplierDetailUrl,
    warehouseItemUrl,
    warehouseAddItemUrl,
    warehouseStateUrl,
    downloadInWarehouseUrl,
    addInWarehouseUrl,
    inWarehouseUrl,
    addOutWarehouseUrl,
    downloadOutWarehouseUrl,
    returnOutWarehouseUrl,
    addMissonUrl,
    missionUrl,
    missionDutyUrl,
    deleteMissionUrl,
    downloadMissionUrl,
    outWarehouseUrl,
    querySingleUrl,
}