
// 查询门禁卡申请记录
const queryApplyAccessCardUrl = "/turnstile/businessDoorCart/adminCart";
// 回收门卡
const recycleCardUrl = '/turnstile/businessDoorCart/businessRecycleCard';
// 拒绝发卡
const rejectApplyCardUrl = '/turnstile/businessDoorCart/businessRefuseReason';
// 创建写卡数据
const createAccessCardDataUrl = '/turnstile/businessDoorCart/businessGettingData';
// 创建员工写卡数据
const  createAccessStaffCardDataUrl = '/account/staff/staffGettingData';
// 发放员工门禁卡
const staffSendCardUrl = '/account/staff/staffSendCard';
// 发放用户门禁卡
const userSendCardUrl = '/turnstile/businessDoorCart/businessSendCard';
// 员工回收门禁卡
const staffRecycleCardUrl = '/account/staff/staffRecycleCard';
//查询区域内门禁闸机
const queryDeviceAllUrl = '/turnstile/deviceManage/queryDeviceAll';
export {
    queryApplyAccessCardUrl,
    recycleCardUrl,
    rejectApplyCardUrl,
    createAccessCardDataUrl,
    createAccessStaffCardDataUrl,
    staffSendCardUrl,
    userSendCardUrl,
    staffRecycleCardUrl,
    queryDeviceAllUrl
}