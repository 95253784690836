//查询公告、新闻(后台和官网用同一个)
const queryWebsiteContentUrl = '/lease/website/queryWebsiteContent';
//添加新增公告、新闻
const addWebsiteContentUrl = '/lease/website/addWebsiteContent';
//修改公告、新闻
const editWebsiteContentUrl = '/lease/website/editWebsiteContent';
//删除公告、新闻
const deleteWebsiteContentUrl = '/lease/website/deleteWebsiteContent';
//查询园区
const queryRegionUrl = 'lease/estateManage/queryRegion';
//设置新增接口
const addWebSiteBindingUrl = '/lease/website/addWebSiteBinding';
//查询设置接口
const queryWebSiteBindingUrl = '/lease/website/queryWebSiteBinding';
//修改设置接口
const editWebSiteBindingUrl = '/lease/website/editWebSiteBinding';
export {
    queryWebsiteContentUrl,
    addWebsiteContentUrl,
    editWebsiteContentUrl,
    deleteWebsiteContentUrl,
    queryRegionUrl,
    addWebSiteBindingUrl,
    queryWebSiteBindingUrl,
    editWebSiteBindingUrl 
}