// 添加检查项
const addCheckItem = "/meter/checkItem/add";
// 搜索验房检查项
const queryCheckItem = "/meter/checkItem/query";
// 删除验房检查项
const deleteCheckItem = "/meter/checkItem/delete";
// 搜索验房记录
const queryCheckRecord = "/meter/checkRecord/queryCheckRecord";
// 查看验房记录详情
const CheckRecordDetails = "/meter/checkRecord/queryCheckById";
// 下载验房记录详情
const downLoadPdf = "/meter/checkRecord/downLoadPdf";
export {
	addCheckItem,
	queryCheckItem,
	deleteCheckItem,
	queryCheckRecord,
	CheckRecordDetails,
	downLoadPdf,
};
