import {loadView} from "@/router/loadFn";

export default [
    {
        path: "quality/assessment",
        name: "qualityAssessment",
        meta: {
            title: "考核汇总",
        },
        component: loadView("Quality/QualityAssessment"),
    },
    {
        path: "quality/AssessmentPointsDeduction",
        name: "AssessmentPointsDeduction",
        meta: {
            title: "考核扣分",
        },
        props: (route) => ({
            detailId: +route.params.detailId,
            state: +route.params.state,
        }),
        component: loadView("Quality/AssessmentPointsDeduction"),
        beforeEnter: (to, from, next) => {
            to.meta.title = to.params.state === 1 ? "考核扣分" : "考核得分";
            next();
        },
    },
    {
        path: "quality/report/log",
        name: "qualityReportLog",
        meta: {
            title: '品质报告记录'
        },
        component: loadView("Quality/Report/QualityReport"),
    },
    {
        path: "quality/report/add",
        name: "qualityReportAdd",
        component: loadView("Quality/Report/QualityReportAdd"),
    },
    {
        path: "quality/report/:type/:id/detail",
        name: "QualityReportDetail",
        meta: {
            title: '报告内容'
        },
        props: (route) => ({
            type: Number(route.params.type), // 1: 编辑  2： 查看
            id: Number(route.params.id),
        }),
        component: loadView("Quality/Report/QualityReportDetail"),
    },
]
