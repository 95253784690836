import { STORAGE_KEY } from "@/constant";
import Vue from "vue";
import VueRouter from "vue-router";
import createHelper from './vue-router-keep-alive-helper';
import routes from "./routes";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);


const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
createHelper({Vue, router});

//  不要验证登录状态的页面
const excludeRouteName = ["login", "defaultPage", "shortNoteBill", "register"];

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem(STORAGE_KEY.X_TOKEN);
  if (token || excludeRouteName.includes(to.name)) {
    next();
  } else {
    sessionStorage.setItem(STORAGE_KEY.BEFORE_LOGIN_PAGE, JSON.stringify(to));
    next({ name: "defaultPage" });
  }

  recordRouterHistory(to);
});

/**
 * 记录路由记录
 * @param {Object} to 前往的页面
 * */
function recordRouterHistory(to) {
  to = {
    meta: to.meta,
    path: to.path,
    name: to.name,
  }
  let routerHistory = JSON.parse(sessionStorage.getItem(STORAGE_KEY.ROUTE_HISTORY)) || [],
      routerHistoryNames = routerHistory.map(r => r.name);
  // 同级的下级页面互斥（防止出现面包屑导航当前模块下点击过的下级页面同时显示出名字的问题）
  const sameLevelRoute = {
    editFlightMission: 'addFlightMission'
  },
    isSameLevelRouteName = Object.keys(sameLevelRoute).includes(to.name),
    isSameLevelRouteVal = Object.values(sameLevelRoute).includes(to.name);
  if (isSameLevelRouteName) {
    to.name = sameLevelRoute[to.name]
  }
  if (routerHistory.length > 0 && routerHistoryNames.includes(to.name)) {
    if ((isSameLevelRouteName || isSameLevelRouteVal) && routerHistoryNames[routerHistoryNames.length - 1] === to.name) {
      routerHistory[routerHistoryNames.length - 1] = to;
      sessionStorage.setItem(STORAGE_KEY.ROUTE_HISTORY, JSON.stringify(routerHistory));
    }
    return ;
  }
  routerHistory.push(to);
  sessionStorage.setItem(STORAGE_KEY.ROUTE_HISTORY, JSON.stringify(routerHistory));
}




export default router;
