// 查询排班管理接口
const queryRosterUrl = '/attendance/rosterResult/queryRoster';
// 上传人脸照片
const uploadFaceImageUrl = '/account/staff/uploadFaceImage';
// 获取人脸识别结果
const checkFaceImgUrl = "/account/staff/checkFaceImage";
// 获取排班信息
const queryRosterArrangementUrl = '/attendance/rosterManage/queryRosterArrangement';
// 查询考勤记录
const queryAttendanceLogsUrl = '/attendance/rosterResult/queryAll';
// 下载考勤excel
const downloadAttendanceDataExcelUrl = '/attendance/rosterResult/downloadAttendanceData';
//下载考勤汇总excel
const downloadAttendanceCountUrl = "/attendance/rosterResult/downloadAttendanceCount";
const exportWaterTonUrl = '/meter/waterMeter/exportWaterTon';
//电表导出
const exportWaterTonDianUrl = '/meter/electricityMeter/exportWaterTon';
// 获取人脸照片
const getFaceImgUrl = "/account/staff/getFaceImage";
// 获取用户人脸照片
const getUserFaceImage = "/account/user/getFaceImage";

export {
    queryRosterUrl,
    uploadFaceImageUrl,
    checkFaceImgUrl,
    queryRosterArrangementUrl,
    queryAttendanceLogsUrl,
    downloadAttendanceDataExcelUrl,
    getFaceImgUrl,
    getUserFaceImage,
    downloadAttendanceCountUrl,
    exportWaterTonUrl,
    exportWaterTonDianUrl
}