//查询物业合同列表
const queryPropertyContractUrl = '/lease/propertyContract/queryPropertyContract';
//新建/变更物业合同
const createPropertyContractUrl = '/lease/propertyContract/createPropertyContract';
//新建物业合同时--查询房间列表
const queryContractBuildingRoomsWUrl ='/lease/room/queryContractBuildingRooms';
//查询计费规则列表
const queryRulesUrl = "/lease/accountingRules/queryRules";
//添加物业缴费规则
const addRulesUrl = "/lease/accountingRules/addRules";
//删除计费规则
const deleteRulesUrl ="/lease/accountingRules/deleteRules";
//修改计费规则
const editRulesUrl = '/lease/accountingRules/editRules';
//根据签订日期 类型 房间 查询对应租客 / 业主
const queryLesseeByRoomIdUrl = '/lease/propertyContract/queryLesseeByRoomId';
//删除计费规则
const deleteRuleswUrl = '/lease/accountingRules/deleteRules';
//根据签订日期查询 账单日
const queryGenerateBillDays = '/lease/propertyContract/generateBillDays';
//根据合同id 查询 对应的房间名称
const queryRoomByPCIdUrl = '/lease/propertyContract/queryRoomByPCId';
//终止物业合同
const terminationPropertyContractUrl = '/lease/propertyContract/terminationPropertyContract';
//查询物业账单列表
const queryBillListsUrl = '/lease/propertyBill/queryBillList';
//查询支付方式
const queryPropPaymentMethodUrl = '/lease/propertyBill/queryPaymentMethod';
//修改账单
const editBillPropertyUrl = '/lease/propertyBill/editBill';
//取消账单
const cancelBillUtilltyUrl = "/lease/propertyBill/cancelBill";
//线下缴费
const pcOffLinePropUrl = '/lease/propertyBill/pcOffLine';
//pc获取二维码链接
const createOrderUtillUrl= "/payment/pay/propertyBill/createOrder";
//pc获取账单信息  支付使用
const propertyBillPcPayUrl="/lease/propertyBill/pcPay";
//修改合同回显数据
const queryPropertyByIdhuiUrl = '/lease/propertyContract/queryPropertyById';
//添加业主查询房间列表
const queryBuildingRoomsWUrl = '/lease/room/queryOwnerBuildingRooms';
//查询楼栋楼层
const queryBuildingFloorUrl = '/lease/room/queryBuildingFloor';
//修改业主信息
const updateEditOwnerLessee = '/lease/lesseeManage/editOwnerLessee';
//判断订单是否支付完成
const queryBillStateIsUrl = '/lease/propertyBill/queryBillState';
//缴费面积统计
const queryAlreadyPayCostUrl = '/lease/payCost/queryAlreadyPayCost';
//修改租客
const editTenantUrl = '/lease/lesseeManage/editTenant';
//查询不分割且未被关联的房间
const queryISNotDivisionRoomUrl = '/lease/room/queryISNotDivisionRoom';

export {
    editTenantUrl,
    queryPropertyContractUrl,
    createPropertyContractUrl,
    queryContractBuildingRoomsWUrl,
    queryRulesUrl,
    addRulesUrl,
    deleteRulesUrl,
    editRulesUrl,
    queryLesseeByRoomIdUrl,
    deleteRuleswUrl,
    queryGenerateBillDays,
    queryRoomByPCIdUrl,
    terminationPropertyContractUrl,
    queryBillListsUrl,
    queryPropPaymentMethodUrl,
    editBillPropertyUrl,
    cancelBillUtilltyUrl,
    pcOffLinePropUrl,
    propertyBillPcPayUrl,
    createOrderUtillUrl,
    queryISNotDivisionRoomUrl,
    queryPropertyByIdhuiUrl,
    queryBuildingRoomsWUrl,
    updateEditOwnerLessee,
    queryBillStateIsUrl,
    queryAlreadyPayCostUrl,
    queryBuildingFloorUrl
}
