//查询工单业务设置接口
const queryTicketConfigUrl = '/ticket/ticketConfig/queryTicketConfig';
//修改或添加工单业务信息
const editTicketConfigUrl = '/ticket/ticketConfig/editTicketConfig';
//修改工单是否有效
const changeValidTicketUrl = '/ticket/ticketConfig/changeValidTicket';
//查询指定部门接单时间
const queryOrderTimeUrl =  '/ticket/ticketConfig/queryOrderTime';

//下载工单记录
const downLoadTicketLogUrl = '/ticket/ticket/downLoadTicketLog';
//查询每天/月的工单数量
const queryCountTicketUrl = '/ticket/ticket/queryCountTicket';
export {
    queryTicketConfigUrl,
    editTicketConfigUrl,
    changeValidTicketUrl,
    queryOrderTimeUrl,
    downLoadTicketLogUrl,
    queryCountTicketUrl
}
