import vueDom from '../main'

const BUCKET_NAMES = {
    IMG: {
        name: 'cosmos-img',
        visitUrl: "https://alioss.techops.cn/"
    }
}

/**
 * params{
 *       MultipartFile [] files,  //文件数组
 *       String bucketName,
 *       Boolean compress    //是否压缩  true压缩
 * }
 */
const uploadFiles = "/account/common/uploadFiles"

/**
 * 上传单张个图片  成功返回Url
 */
const uploadFileOne = async function (formData) {
    try {
        let res = await vueDom.$fly.post(uploadFiles, formData);
        if (res.code === 0 && res.data && res.data.length > 0) {
            return res.data[0].ossUrl;
        }
    } catch (e) {
        return;
    }
    return;
}


export {
    BUCKET_NAMES,
    uploadFileOne
}
