// 出租面积
const queryTenantRoomAreaUrl = '/lease/contractManage/queryRoomArea';
// 通行人员身份占比
const queryGatePassByTypeUrl = '/turnstile/deviceManage/queryGatePassByType';
// 每小时通行流量
const queryGatePassByHourUrl = '/turnstile/deviceManage/queryGatePassByHour';
// 获取监控探头数量
const  queryAICameraCountUrl = '/monitor/camera/getCameraCount';
// 闸机数量
const queryDeviceCountUrl = '/turnstile/deviceManage/queryDeviceCount';
// 查询传感器
const querySensorCountUrl = '/meter/equipment/bigDataEquipmentCount';
// 获取beacon设备数量
const queryIBeaconCountUrl = '/inspection/location/queryBeaconByRegionCode';
// 获取最新的闸机出入记录
const queryPassingLogByIdUrl = '/turnstile/deviceManage/queryPassingLogById';
// 获取异常抓拍数据
const queryBigDataCapturePhotoAbnormalUrl = '/monitor/camera/getCapturePhotoAbnormal';
// 最新订单
const queryNewOrderUrl = '/public/mallManage/queryNewOrder';
// 最新活动
const queryActivityByRegionCodeUrl = '/social/activity/queryActivityByRegionCode';
// 查询社区访问人数和苹果访问人数
const querySocialCountAndAppleTreeCountUrl = '/social/points/queryCountNumber';
// 查询各服务访问人数
const queryBrowseServiceCountNumberUrl = '/public/mall/queryCountNumber';
// 服务购买人次
const queryBuyServiceCountUrl = '/public/visiting/queryBuyCountByRegionCode';
// 社区发帖人数、回复人数
const queryPostPersonCountByRegionCodeUrl = '/social/post/queryPostPersonCountByRegionCode';
// 社区发帖，回复数
const queryPostCountByRegionCodeUrl = '/social/post/queryPostCountByRegionCode';
// 查询评级星级
const queryEvaluateScoreUrl = '/public/visiting/queryScoreByRegionCode';
// 查询帖子类型占比
const  queryPostPercentageByRegionCodeUrl = '/social/post/queryPostPercentageByRegionCode';
// 累计购买人次
const queryBigDataSumOrderUrl = '/public/visiting/querySumOrder';
// 累计处理报事
const queryBigDataTicketUrl = '/ticket/ticket/queryCountTicket';
// 商城订单数据
const queryMallOrderCountUrl = '/public/visiting/queryMallByRegionCode';
// 摘苹果，兑现金人数
const queryPointsManageUrl = '/social/pointsManage/queryPointsManage';
// 产生苹果，消耗苹果
const queryAppleCountUrl = '/social/pointsManage/queryAppleCount';
// 查询兑换，坠落苹果数量
const queryExchangeAppleCountUrl = '/social/pointsManage/queryPointsManageCount';
//最新用户提交的问卷
const querySurveyBigDataUrl = '/survey/survey/querySurveyBigData';
// 工单数
const queryTicketStatusCountUrl = '/ticket/ticket/queryTicketByRegionCode';
// 各种工单类型占比
const queryTickerTypeProportionUrl = '/ticket/ticket/queryTicketTypeByRegionCode';
// 工单评价
const queryTicketEvaluateUrl = '/ticket/ticket/queryTicketAppraise';
// 品质检查
const queryInspectionReportUrl = '/evaluation/qualityManage/queryInspectionReport';
// 巡检情况(巡检总数、异常占总数百分比)
const queryBigDataInspectTaskUrl = '/inspection/inspection/queryInspectTaskByRegionCode';
// 员工考勤结果
const queryAttendanceRosterResultUrl = '/attendance/rosterResult/queryAttendanceByRegionCode';
// 各部门员工扣分汇总
const queryDepartmentScoreUrl = '/evaluation/evaluationResult/queryScoreByRegionCode';
// 抄表
const queryBigDataMeterUrl = '/meter/mater/queryMeterByRegionCode';
// 查询验房
const queryBigDataCheckRoomUrl = '/meter/check/queryCheckByRegionCode'
// 查询盘点数据
const queryBigDataStocktakingUrl = '/inventory/stocktaking/queryDetailByRegionCode';
// 最新工单
const queryNewTicketUrl = '/ticket/ticket/queryNewTicket';
// 查询管理区详情
const queryRegionInfoUrl = '/lease/estate/region';

// 楼内用户人数查询
const queryUserCountUrl = '/account/user/queryUserCount';
// 楼内用户男女性别比例
const queryGenderRateUrl = '/account/user/queryGenderRate';
// 租赁情况
const queryRoomAreaUrl = '/lease/contractManage/queryRoomArea';
// 楼内用户年龄分布
const queryAgeIntervalUrl = '/account/user/queryAgeInterval';
// 楼栋出租率排名
const queryBuildingOccRateUrl = '/lease/bigData/queryBuildingOccRate';
// 园区公司统计
const queryLesseeCountUrl = '/lease/bigData/queryLesseeCount';
// 园区企业员工数前十统计
const queryCompanyCountUrl = '/account/user/queryCompanyCount';
// 水表数量 以及昨日消耗记录
const queryMeterCountUrl = '/meter/basicSetup/queryMeterCount';
// 企业租赁面积比例统计
const queryLesseeSpaceUrl = '/lease/bigData/queryLesseeSpace';
// 近半年空置率走势
const queryIdleRateUrl = '/lease/bigData/queryIdleRate';
// 近半年用水百分比
const queryHalfYearConsumeUrl = '/meter/basicSetup/queryHalfYearConsume';
// 近半年用水走势
const queryUserWaterTrendUrl = '/meter/meter/queryUserWaterTrend';
// 近七天企业用水量
const queryLesseeUseWaterUrl = '/meter/meter/queryLesseeUseWater';
// 室外环境数据
const queryBigDataOutdoorUrl = '/meter/outdoor/queryBigDataOutdoor';
//天气实时数据
const queryCurrentUrl = `/public/current/queryCurrent`;
//
const queryLesseeUseElectricityUrl = '/meter/meter/queryLesseeUseElectricity';
//人间检测数据
const queryFaceDataBigDataUrl = '/monitor/face/data/queryFaceDataBigData';
// 室内环境数据
const queryBigDataIndoorUrl = '/meter/indoor/queryBigDataIndoor';
// 室外环境24小时分布
const queryBidDataEnvironmentAndElectricityUrl = '/meter/outdoor/queryBidDataEnvironmentAndElectricity';
//近30天用电走势
const queryUserElectricityTrendUrl = '/meter/meter/queryUserElectricityTrend';
// 大数据查询近七天进场数据（停车场）
const inSevenDayEnterDataUrl = '/parking/inOutLog/inSevenDayEnterData';
// 近七天物品出入库记录
const queryBigDataGoodsUrl = '/inventory/goods/queryBigDataGoods';
// 最新车辆进出记录
const queryNewInOutLogUrl = '/parking/inOutLog/queryNewInOutLog';
//室外实时环境数据
const getOutDoorTemperatureUrl = '/meter/weather/getOutDoorTemperature';

// 3D中的大数据接口
// 3D页面根据楼栋名称查询楼栋Code
const queryBuildingCodeByNameUrl = "/lease/model/queryBuildingCodeByName";
// 3D页面根据楼栋查询入驻企业
const queryLesseeByBuildingUrl = "/lease/lesseeManage/queryLesseeByBuilding";
// 3D页面根据楼栋查询设备数
const queryDeviceByBuildingUrl = "/deviceManage/queryDeviceByBuilding";
// 3D页面根据楼栋查询用户信息
const queryUserByBuildingUrl = "/account/user/queryAllUserRelated";
//最新工单状态
const queryTicketLogUrl = '/ticket/ticket/queryTicketLog';

let  wsChatUrl ;
if(process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'testm'){
    wsChatUrl = 'ws://117.160.239.182:8028/api/chat'
}
else{
    wsChatUrl = 'wss://app.techops.cn/api/chat'
}
console.log('推送的链接');
console.log(process.env.NODE_ENV);
console.log(wsChatUrl);
export {
    queryTicketLogUrl,
    queryTenantRoomAreaUrl,
    queryGatePassByTypeUrl,
    queryGatePassByHourUrl,
    queryAICameraCountUrl,
    queryDeviceCountUrl,
    querySensorCountUrl,
    queryIBeaconCountUrl,
    queryPassingLogByIdUrl,
    queryBigDataCapturePhotoAbnormalUrl,
    queryNewOrderUrl,
    queryActivityByRegionCodeUrl,
    querySocialCountAndAppleTreeCountUrl,
    queryBrowseServiceCountNumberUrl,
    queryBuyServiceCountUrl,
    queryPostPersonCountByRegionCodeUrl,
    queryPostCountByRegionCodeUrl,
    queryEvaluateScoreUrl,
    queryPostPercentageByRegionCodeUrl,
    queryBigDataSumOrderUrl,
    queryMallOrderCountUrl,
    queryPointsManageUrl,
    queryAppleCountUrl,
    queryExchangeAppleCountUrl,
    queryTicketStatusCountUrl,
    queryTickerTypeProportionUrl,
    queryTicketEvaluateUrl,
    queryInspectionReportUrl,
    queryBigDataInspectTaskUrl,
    queryAttendanceRosterResultUrl,
    queryDepartmentScoreUrl,
    queryBigDataMeterUrl,
    queryBigDataCheckRoomUrl,
    queryBigDataStocktakingUrl,
    queryNewTicketUrl,
    queryRegionInfoUrl,
    queryLesseeUseElectricityUrl,
    wsChatUrl,
    queryFaceDataBigDataUrl,
    queryUserCountUrl,
    queryGenderRateUrl,
    queryRoomAreaUrl,
    queryAgeIntervalUrl,
    queryBuildingOccRateUrl,
    queryLesseeCountUrl,
    queryCompanyCountUrl,
    queryMeterCountUrl,
    queryLesseeSpaceUrl,
    queryIdleRateUrl,
    queryHalfYearConsumeUrl,
    queryBigDataOutdoorUrl,
    queryBigDataIndoorUrl,
    queryBidDataEnvironmentAndElectricityUrl,
    inSevenDayEnterDataUrl,
    queryUserWaterTrendUrl,
    queryLesseeUseWaterUrl,
    queryBigDataGoodsUrl,
    queryNewInOutLogUrl,
    queryBuildingCodeByNameUrl,
    queryLesseeByBuildingUrl,
    queryDeviceByBuildingUrl,
    queryUserByBuildingUrl,

    queryUserElectricityTrendUrl,
    getOutDoorTemperatureUrl,
    querySurveyBigDataUrl,
    queryCurrentUrl,
    queryBigDataTicketUrl
}
