//  添加商品
const addMallItemUrl = "/public/mallManage/addItem";
//  编辑商品
const editMallItemUrl = "/public/mallManage/editItem";
// 修改商品上下架
const changeMallAvailableUrl = "/public/mallManage/editAvailable";
//  获取商品列表
const queryMallItemsUrl = "/public/mallManage/queryItems";
// 查询商城订单列表
const queryMallOrderUrl = "/public/mallManage/queryOrderList";
//  查看商品详情
const queryMallCommodityUrl = "/public/mallManage/item";
// 获取商品自提点列表
const queryPickupLocationUrl = "/public/mallManage/querySelfPickupLocation";
// 启用，禁用自提点
const changePickupEnableUrl =
    "/public/mallManage/updateSelfPickupLocationEnable";
//  签收商品
const signForDeliveryUrl = "/public/mallManage/signForDelivery";
// 编辑自提点
const editPickupLocationUrl = "/public/mallManage/editSelfPickupLocation";
//  添加自提点
const addPickupLocationUrl = "/public/mallManage/addSelfPickupLocation";

//  退款订单（微信操作）
const refundOrderUrl = "/payment/pay/refundOrder";

const ticketOfflinePaymentUrl = "/ticket/ticket/adminOfflinePayment";
//  发送短信验证码
const sendSmsCodeUrl = "/account/auth/sendSms";
//  登录接口
const signInUrl = "/account/auth/smsSignIn";
// 获取菜单列表
const getMenuUrl = "/account/staff/getMenu";
// 创建公司/部门
const createDepartmentUrl = "/account/organizationManage/addDepartment";
// 编辑公司/部门
const editDepartmentUrl = "/account/organizationManage/editDepartment";
// 删除公司/部门
const deleteDepartmentUrl = "/account/organizationManage/deleteDepartment";
// 查询公司/部门
const queryDepartmentUrl = "/account/organizationManage/query";
// 查询所有部门
const queryAllDepartmentUrl = "/account/organizationManage/queryAll";
//  获取省级行政区
const getProvincesUrl = "/lease/regionalism/provinces";
// 获取省级以下行政区
const getRegionalismUrl = "/lease/regionalism/query";
// 获取园区列表
const queryRegionListUrl = "/lease/estateManage/queryRegion";
// 删除园区
const deleteRegionUrl = "/lease/estateManage/deleteRegion";
// 创建园区
const createRegionUrl = "/lease/estateManage/createRegion";
// 编辑园区
const editRegionUrl = "/lease/estateManage/editRegion";
// 查询楼栋列表
const queryBuildingUrl = "/lease/estateManage/queryBuilding";
// 删除楼栋 HACK: 该接口已作废
const deleteBuildingUrl = "/lease/estateManage/deleteBuilding";
// 修改楼栋状态
const changeBuildingStateUrl = '/lease/estateManage/changeBuildingState';
// 编辑楼栋 HACK: 该接口已作废
const editBuildingUrl = "/lease/estateManage/editBuilding";
// 创建楼栋
const creatBuildingUrl = "/lease/estateManage/createBuilding";
// 创建资源接口
const createResourceUrl = "/account/rbacManage/addResource";
// 编辑资源
const editResourceUrl = "/account/rbacManage/editResource";
// 启用资源
const enableResourceUrl = "/account/rbacManage/enableResource";
// 禁用资源
const disableResourceUrl = "/account/rbacManage/disableResource";
// 查询资源接口
const queryResourceUrl = "/account/rbacManage/queryResources";
// 获取角色列表
const queryRoleUrl = "/account/rbacManage/queryRole";
// 删除角色
const deleteRoleUrl = "/account/rbacManage/deleteRole";
// 编辑角色
const editRoleUrl = "/account/rbacManage/editRole";
// 创建角色
const createRoleUrl = "/account/rbacManage/addRole";
// 查询角色所拥有的资源权限
const queryRoleResourcesUrl = "/account/rbacManage/queryRoleResources";
//  设置角色-资源关系接口
const setupRoleResourcesUrl = "/account/rbacManage/setupRoleResources";

//查询员工的菜单
const queryMenuByUserUrl = "/account/rbacManage/queryMenuByUser";
//  查询部门员工
const queryDepartmentStaffUrl = "/account/staff/queryDepartmentStaff";
//  查询指定部门在职员工
const queryStaffByDutyTypesUrl = "/account/staff/queryStaffByDutyTypes";
//查询指定年份/日期 工单数量
const queryTicketCountUrl = '/ticket/ticket/queryTicketCount';
//  查询所有部门员工
const queryAllDepartmentStaffUrl = "/account/staff/queryAllDepartmentStaff";
//  添加员工
const addStaffUrl = "/account/staff/addUser";
//  编辑员工
const editStaffUrl = "/account/staff/editUser";
//  标记员工离职
const resignStaffUrl = "/account/staff/resignUser";
//  标记员工入职
const entryUserUrl = "/account/staff/entryUser";

// 上传照片
const uploadPhotoUrl = "/uploadFile";
//设置用户-角色关系
const setupUserRoleUrl = "/account/rbacManage/setupUserRole";
// 查询用户绑定的角色
const queryUserRoleUrl = "/account/rbacManage/queryUserRoles";

//  撤回工单
const reopenTicketUrl = "/ticket/ticket/reopen";
// 分配工单
const assignTicketUrl = "/ticket/ticket/assign";
// 删除工单
const deleteTicketUrl = "/ticket/ticket/delete";
// 查询工单
const queryTickerUrl = "/ticket/ticket/adminQuery";
// 创建工单
const createTicketUrl = "/ticket/ticket/create";
// 编辑工单
const editTicketUrl = "/ticket/ticket/edit";
// 查询工单类型
const queryEnableTicketTypeUrl = '/ticket/ticket/queryEnableType';
// 更改工单类型启用状态
const updateEnableTicketTypeUrl = '/ticket/ticket/updateEnableType';
// 设置工单超时时限
const setTicketTimeoutUrl = "/ticket/ticket/assignTimeoutConfig";
// 获取工单超时时限设置
const getTicketTimeoutUrl = "/ticket/ticket/timeoutConfigs";
// 获取业务考核项目数据
const listProfessionalItemUrl =
    "/evaluation/evaluationManage/listProfessionalItem";
// 获取考核表数据
const listGeneralFormUrl = "/evaluation/evaluationManage/listGeneralForm";

// 获取考核规则
const queryAutomationConfigRulesUrl = "/evaluation/automationConfig/queryRules";
// 设置考核规则
const setupAutomationConfigRulesUrl = "/evaluation/automationConfig/setupRules";
// 禁用考核表
const disableGeneralFormUrl = "/evaluation/evaluationManage/disableGeneralForm";
// 禁用通用考核项目
const disableGeneralItemUrl = "/evaluation/evaluationManage/disableGeneralItem";
// 禁用业务考核项目
const disableProfessionalItemUrl =
    "/evaluation/evaluationManage/disableProfessionalItem";
// 添加考核表
const addGeneralFormUrl = "/evaluation/evaluationManage/addGeneralForm";
// 编辑考核表
const editGeneralFormUrl = "/evaluation/evaluationManage/editGeneralForm";

// 添加通用考核项
const addGeneralItemUrl = "/evaluation/evaluationManage/addGeneralItem";
// 编辑通用考核项
const editGeneralItemUrl = "/evaluation/evaluationManage/editGeneralItem";

// 添加业务考核项
const addProfessionalItem = "/evaluation/evaluationManage/addProfessionalItem";
// 编辑业务考核项
const editProfessionalItem =
    "/evaluation/evaluationManage/editProfessionalItem";

const automationDetailsUrl = "/evaluation/evaluationResult/automationDetails";
const professionalDetailsUrl =
    "/evaluation/evaluationResult/professionalDetails";

const generalDetailsUrl = "/evaluation/evaluationResult/generalDetails";

const professionalSummaryUrl =
    "/evaluation/evaluationResult/professionalSummary";
const generalSummaryUrl = "/evaluation/evaluationResult/generalSummary";
const automationSummaryUrl = "/evaluation/evaluationResult/automationSummary";

// 查询特定的工单详情
const queryTicketUrl = "/ticket/ticket/innerQuery";

// 获取考勤平台设置
const queryAttendanceVendorUrl = "/attendance/vendor/query";
// 修改考勤平台设置
const editVendorConfigUrl = "/attendance/vendor/editConfig";
// 考勤平台设置
const setupVendorConfigUrl = "/attendance/vendor/setupConfig";

const queryAttendanceLogUrl = "/attendance/attendance/query";

const queryHandoverLogUrl = "/attendance/handover/query";

const downloadHandoverLogUrl = '/attendance/handover/downloadHandoverLog';

//考勤汇总获取
const getAttendanceSummaryUrl = "/attendance/rosterResult/queryAttendanceCount";

const passingLogUrl = "/turnstile/passingLog";
// 通行限制
const setRestrictConfigUrl = '/turnstile/config/setupRestrict';
const queryRestrictConfigUrl = '/turnstile/config/queryRestrict';

const setValhallaAdConfigUrl = "/valhalla/adConfig";

// 楼栋信息接口

// 创建房间
const createRoomUrl = "/lease/estateManage/createRoom";
//  查询房间
const queryRoomUrl = "/lease/estateManage/queryRegionRoomList";
//  查询房间(含出租信息)
const queryRoomRentUrl = "/lease/estateManage/queryRoomRentList";
const queryRoomRentUrls = "/lease/estateManage/queryRoomRentListByPage";
const queryRoomRentUrld= '/lease/tenantRoom/queryRoomRent';
//  查询房间(不含出租信息)
const queryRoomRentSimplifyUrl = "/lease/estateManage/queryRoomRentListSimplify";
// 查询租客绑定的房间
const queryRenterBindRoomsUrl = "/lease/contractManage/lesseeRoomRentList";
// 修改房间状态
const changeRoomStateUrl = '/lease/estateManage/changeRoomState';
// 编辑房间
const editRoomUrl = "/lease/estateManage/editRoom";
// 删除房间
const deleteRoomUrl = "/lease/estateManage/deleteRoom";
//查询租客直接绑定的放进
const queryLesseeBindRoomsUrl = "/lease/tenantRoom/queryLesseeId";

//查询分割房间
const queryAllDivisionRoomUrl = '/lease/room/queryAllDivisionRoom';

// (H5)账单详情接口
const billUrl = "/lease/bill/h5BillInfo";
// 组控图详情查询接口
const roomStateDetailUrl = "/lease/contractManage/roomRentDetail";
// 组控图查询接口
const stateRoomUrl = "/lease/contractManage/roomRentList";
// 查看合同详情接口
const pactDetailsUrl = "/lease/contractManage/detail";
// 查询租客信息
const queryTenantUrl = "/lease/lesseeManage/queryLessee";
//查询工单数量
const queryTicketCountByTimeUrl = '/ticket/ticket/queryTicketCountByTime'
// 新建租客信息
const createTenantUrl = "/lease/lesseeManage/addLessee";
//  修改租客信息
const editTenantInfoUrl = "/lease/lesseeManage/editLessee";
//  删除租客信息
const deleteTenantInfoUrl = "/lease/lesseeManage/deleteLessee";
// 查询租客房间
const queryLesseeRoomByLesseeIdUrl = '/lease/contractResult/queryByLesseeId';
//查询分割房间列表
const queryIsDivisionRoomUrl = '/lease/room/queryIsDivisionRoom';
// 创建合同
const createContractUrl = "/lease/contractManage/create";
// 计算合同应付租金
const calcContractRentUrl = "/lease/contractManage/calcRent";
//  查询合同
const queryContractListUrl = "/lease/contractManage/query";
// 取消合同
const cancelContractUrl = "/lease/contractManage/cancel";
// 终止合同
const terminateContractUrl = "/lease/contractManage/terminate";
// 转移合同
const transferContractUrl = "/lease/contractManage/transfer";
// 续签合同
const preRenewContractUrl = "/lease/contractManage/preRenew";
// 变更合同
const preUpdateContractUrl = "/lease/contractManage/preUpdate";
// 查看合同详情
const queryContractDetailUrl = "/lease/contractManage/detail";

// 添加合同附件
const addContractAttachmentsUrl = "/lease/contractManage/addAttachments";
// 删除合同附件
const deleteAttachmentUrl = '/lease/contractManage/delAttachment';
// 添加合同绑定房间照片
const queryContractRoomPictureUrl = "/lease/contractManage/addRoomPicture";

// 查询菜单是否需要显示红点
const queryBillMenuTipsStatusUrl = "/lease/billManage/reddot";
// 设置账单提醒缴费时间
const setBillNotifyConfigUrl = "/lease/billManage/setupNotify";
// 查询账单提醒缴费时间
const queryBillNotifyConfigUrl = "/lease/billManage/queryNotifyConfig";
// 获取账单列表
const queryBillListUrl = "/lease/billManage/query";
// 发送账单支付通知
const sendBillPaymentNotifyUrl = "/lease/billManage/notifyBillPayment";
//  查看账单详情
const queryBillDetailUrl = "/lease/billManage/queryDetail";
// 提交线下支付结果
const commitPaymentUrl = "/lease/billManage/commitPayment";

// 查询账单接口
const financeQueryUrl = "/ticket/ticket/financeQuery";
// 线下付款接口
const adminOfflinePaymentUrl = "/ticket/ticket/adminOfflinePayment";
// 提抱工单接口
const adminOfflinecreateUrl = "/ticket/ticket/create";
// 后台查询会议室接口
const adminQueryMeetingRoomUrl = "/public/meetingRoom/adminQueryMeetingRoom";
//  查询会议室详情
const adminQueryMeetingRoomByIdUrl = "/public/meetingRoom/adminQueryById";
// 后台添加会议室接口
const addMeetingRoomUrl = "/public/meetingRoom/addMeetingRoom";
//  已退款的会议室订单
const refundMeetingRoomUrl = "/public/meetingRoom/adminQueryRefund";
// 会议室财务账单
const queryMeetingRoomBillUrl = "/public/meetingRoom/financeQuery";

//  会议室订单
const queryMeetingRoomOrderUrl = "/public/meetingRoom/adminQueryRoomDetail";
// 删除会议室
const deleteMeetingRoomUrl = "/public/meetingRoom/deleteMeetingRoom";
// 上下架会议室
const changeEnableMeetingRoomUrl = "/public/meetingRoom/isEnable";
// 修改会议室接口
const updateMeetingRoomUrl = "/public/meetingRoom/updateMeetingRoom";
// 查询会议室详情
const queryMeetingRoomDetailUrl = "/public/meetingRoom/userQuery";
// 上门服务查询订单接口
const adminQueryOrderUrl = "/public/visiting/adminQueryOrder";
// 上门服务已退款查询接口
const adminQueryRefundOrderUrl = "/public/visiting/adminQueryRefundOrder";
// 上门服务添加服务接口
const addVisitingItemsUrl = "/public/visiting/addVisitingItems";
// 上门服务项目管理后台查询接口
const adminQueryItemsUrl = "/public/visiting/adminQueryItems";

//  更改上门服务上下架状态
const changeVisitingServiceEnableStateUrl = "/public/visiting/isEnable";

//  上门服务通知用户
const visitingServiceNotificationUrl = "/public/visiting/newItemsInformUser";
// 删除上门服务
const deleteVisitingServiceItemUrl = "/public/visiting/deleteItem";
// 添加上门服务
const addVisitingServiceItemUrl = "/public/visiting/addVisitingItems";

// 编辑上门服务
const editVisitingServiceItemUrl = "/public/visiting/editItem";
// 获取上门服务详情
const queryVisitingServiceItemDetailUrl = "/public/visiting/queryItem";
// 获取上门服务账单
const queryVisitingServiceBillUrl = "/public/visiting/financeQuery";

// 会议室开门接口
const sweepCodeToOpenDoorlUrl = "/public/meetingRoom/sweepCodeToOpenDoor";
// 会议室线下付款
const meetingRoomOfflinePaymentUrl = '/public/meetingRoom/offlinePayment';
// 会议室关门接口
const closeDoorSettlementUrl = "/public/meetingRoom/closeDoorSettlement";
// 企业服务账单
const getfinanceQueryUrl = "/public/enterprise/financeQuery";
// 企业服务查看订单接口
const getadminQueryOrderUrl = "/public/enterprise/adminQueryOrder";
// 企业服务签约接口
const signContractUrl = "/public/enterprise/sign";
// 企业服务项目管理查询接口
const getadminQueryItemsUrl = "/public/enterprise/adminQueryItems";
// 添加企业服务接口
const addItemUrl = "/public/enterprise/addItem";
// 编辑企业服务
const amendVisitingServiceItemUrl = "/public/enterprise/editItem";
// 删除企业服务项目接口
const deleteEnterpriseServiceItemUrl = "/public/enterprise/deleteItem";
//  查询企业服务详情
const queryEnterpriseServiceItemDetailUrl = "/public/enterprise/queryItem";
// 改变企业服务上下架
const changeEnableEnterpriseServiceItemUrl = "/public/enterprise/isEnable";
// 添加问卷调查
const addSurveyUrl = "/survey/survey/addSurvey";
// 后台问卷查询
const adminQueryUrl = "/survey/survey/adminQuery";
// 后台查询某个问卷
const userFillSurveyUrl = "/survey/survey/userFillSurvey";

// 后台查看数据统计的接口
const adminQueryCommitLogUrl = "/survey/survey/adminQueryCommitLog";
// 后台查看数据统计的接口
const adminQueryOptionDetailUrl = "/survey/survey/adminQueryOptionDetail";
// 社区后台查询帖子管理接口
const adminQueryPostlUrl = "/social/post/adminQueryPost";
// 社区后台查询帖子管理接口
const adminQuerySensitiveUrl = "/social/post/adminQuerySensitive";
// 社区后台查询已删除接口
const adminQueryDelReplyUrl = "/social/post/adminQueryDelReply";
// 社区后台查询禁言用户接口
const adminQueryBlockUrl = "/social/post/adminQueryBlock";
// 社区后台删除和恢复接口
const adminDelReplyUrl = "/social/post/adminDelReply";
// 社区后台禁言丶取消禁言接口
const adminBlockUrl = "/social/post/adminBlock";
// 社区后台置顶丶取消置顶接口
const adminIsTopUrl = "/social/post/adminIsTop";
// 社区后台删除和恢复帖子接口
const adminDelPostUrl = "/social/post/adminDelPost";
//  删除问卷
const deleteQuestionnaireUrl = "/survey/survey/delete ";
//  启用、禁用问卷
const changeEnableQuestionnaireUrl = "/survey/survey/adminIsEnable";
//  启用、禁用问卷
const adminQueryReplyUrl = "/social/post/adminQueryReply";
//  后台查询公告接口
const getAdminQueryUrl = "/social/notice/adminQuery";
//  后台删除公告接口
const adminDelUrl = "/social/notice/adminDel";
//  后台上架 下架公告接口
const adminIsEnableUrl = "/social/notice/adminIsEnable";
//  后台查询活动接口
const getadminQueryUrl = "/social/activity/adminQuery";
//  后台删除活动接口
const deleteActivityUrl = "/social/activity/adminDel";
//  后台添加公告接口
const addUrl = "/social/notice/add";
//  后台活动查询数据统计口
const adminQueryCensusUrl = "/social/activity/adminQueryCensus";
//  后台登录页添加公告接口
const addPCLoginUrl = "/social/notice/addPCLogin";
//  后台登录页查询公告接口
const queryPCLoginUrl = "/social/notice/queryPCLogin";
//  查询问卷数据
const queryQuestionnaireCensusUrl = "/survey/survey/adminQueryCensus";
//  添加活动接口
const addActivityUrl = "/social/activity/add";
//  取消预警接口接口
const adminCancelAuditUrl = "/social/post/adminCancelAudit";
//  下载签到二维码接口
const downloadQrCodeUrl = "/social/activity/downloadQrCode";
//  查看某个活动丶公告丶帖子的接口
const userQueryAnPostUrl = "/social/post/userQueryAnPost";
//  上传企业服务附件
const uploadEnterpriseAnnexUrl = "/public/enterprise/uploadAnnex";
// 删除附件
const deleteAnnexUrl = '/public/enterprise/deleteAnnex';
//  企业服务线下付款
const enterpriseOfflinePaymentUrl = "/public/enterprise/offlinePayment";

//查询苹果产生的数据接口
const querySocialServiceAppleProduceDataUrl = "/social/pointsManage/summary";
//查询苹果红包数据接口
const querySocialServiceAppleRedPacketDataUrl =
    "/social/redPackManage/queryRedPack";
//查询苹果红包标题数据接口
const querySocialServiceAppleRedPacketTitleDataUrl =
    "/social/redPackManage/summary";
//查询苹果红包详情数据接口
const querySocialServiceAppleRedPacketDetailsDataUrl =
    "/social/redPackManage/grabDetails";
//查询苹果卖出数据接口
const querySocialServiceAppleSellDataUrl = "/social/pointsManage/queryExchange";
//查询苹果卖出中的苹果来源数据接口
const querySocialServiceSellSourceDataUrl =
    "/social/pointsManage/queryExchangeDetail";
//查询苹果卖出标题数据
const querySocialServiceSellTitleDataUrl =
    "/social/pointsManage/queryExchangeSummary";
//冻结/解冻积分接口
const socialServiceBlockExchangeUrl = "/social/pointsManage/blockExchange";
//发放积分接口
const socialIssueExchangeUrl = "/social/pointsManage/finishExchange";

//  创建苹果红包
const createAppleRedPackUrl = "/social/redPackManage/createRedPack";
//  查询苹果红包
const queryAppleRedPackUrl = "/social/redPackManage/queryRedPack";
//  更改苹果红包活动状态
const updateAppleRedPacketStateUrl = "/social/redPackManage/updateState";
// 设置苹果产生和修改的配置
const setAppleConfigUrl = "/social/pointsManage/setupConfig";
// 获取苹果设置
const getAppleConfigUrl = "/social/pointsManage/getConfig";
// 添加红包活动
const addRedPacketActivityUrl = "/social/activity/addRedPacketActivity";

// 物业数据查询报事/投诉数据接口
const queryTicketPropertyDataUrl = "/ticket/ticket/propertyData";
// 物业数据查询报事/投诉图表数据接口
const queryTicketPropertyChartDataUrl = "/ticket/ticket/propertyChartData";

// 物业数据查询员工考核数据接口
const queryEvaluationPropertyDataUrl =
    "/evaluation/evaluationManage/propertyData";
// 物业数据查询员工考核图表数据接口
const queryEvaluationPropertyChartDataUrl =
    "/evaluation/evaluationManage/propertyChartData";
// 物业数据查询人事数据接口
const queryAccountPropertyDataUrl = "/account/staff/propertyData";
// 物业数据查询人事图表数据接口
const queryAccountPropertyChartDataUrl = "/account/staff/propertyChartData";
// 物业数据查询品质检查报告数据接口
const queryReportPropertyDataUrl = "/evaluation/qualityManage/propertyData";
//  上门服务退款
const refundVisitingOrderUrl = "/public/visiting/refund";
//  查询红点提示
const queryRedDotUrl = "/account/redDot/reddot";
// 工单退款
const refundTicketUrl = "/ticket/ticket/refund";
//  企业服务退款
const refundEnterpriseUrl = "/public/enterprise/refund";
// 招商设置
const saveConfigLeaseCrmUrl = "/lease/crmManage/saveConfig";
// 获取招商的设置
const queryCrmConfigUrl = "/lease/crmManage/getConfig";
// 获取客户数据
const getCustomerLeadsUrl = "/lease/crm/fetchPublicLeads";
// 查询临时私有客户池信息
const queryPublicCustomerUrl = "/lease/crm/queryLockedLeads";
// 查询临时私有客户池
const queryTempCustomerPoolUrl = '/lease/leadsManage/queryLockedLeads';
// 上传excel
const uploadExcelUrl = "/lease/leadsManage/uploadExcel";
// 添加至私有客户池接口
const lockedToOpportunityUrl = "/lease/crm/lockedToOpportunity";
// 添加跟进记录接口
const addFollowUpLogUrl = "/lease/crm/addFollowUpLog";
// 查询客户联系人
const queryLeadContactsUrl = "/lease/crm/queryLeadContacts";
// 查询私有客户
const queryOpportunityUrl = "/lease/crm/queryOpportunity";
// 添加私有客户
const addOpportunityUrl = "/lease/crm/addOpportunity";
// 释放私有客户
const releaseOpportunityUrl = "/lease/crm/releaseOpportunity";
// 查询认租客户
const querySubscriptionsUrl = "/lease/crm/querySubscriptions";
const querySubscriptionsdUrl = "/lease/crm/queryQualificationSubscriptions";
// 审核认租客户
const auditSubscribeUrl = "/lease/leadsManage/auditSubscribe";


// 查询客户信息
const queryCustomerInfoUrl = "/lease/crm/queryLeadInfo";
// 更新客户信息
const updateCustomerInfoUrl = "/lease/crm/updateLeadInfo";
// 查询跟近客户记录
const queryCustomerFollowLogUrl = "/lease/crm/queryFollowUpLog";
// 删除客户联系人
const deleteContactUrl = "/lease/crm/deleteLeadContacts";
// 设置默认联系人
const setupDefaultContactsUrl = "/lease/crm/setupDefaultContacts";
// 添加客户信息下的联系人
const addLeadContactsUrl = "/lease/crm/addLeadContacts";
// 编辑客户信息中的联系人
const editLeadContactsUrl = "/lease/crm/editLeadContacts";
// 签约客户
const signLeadContractUrl = "/lease/leadsManage/signContract";
// 删除用户
const deleteCustomerUrl = "/lease/leadsManage/deleteLead";
// 转移用户id
const transferLeadUrl = "/lease/leadsManage/transferLead";
// 查询可出租的房间
const queryBuildingRoomsUrl = "/lease/crm/buildingRooms";
// 确认意向房间或者认租房间
const addIntentionRoomsUrl = "/lease/crm/addIntentionRooms";
// 查询公共客户池
const queryPublicLeadsUrl = "/lease/crm/queryPublicLeads";
//分配网络收集的客户
const assigningPublicClientUrl = "/lease/crm/assigningPublicClient";
// 查询意向房间
const queryIntentionRoomsUrl = '/lease/crm/queryIntentionRooms';
// 公海转私有
const publicToOpportunityUrl = '/lease/crm/publicToOpportunity';

// 添加品质管理考核
const addQualityAssessmentItemUrl = '/evaluation/qualityManage/insertDetail';
// 查询品质管理考核
const queryQualityAssessmentUrl = '/evaluation/qualityManage/adminQueryDetail';
// 修改品质管理考核
const editQualityAssessmentUrl = '/evaluation/qualityManage/updateDetail';
// 删除品质管理考核
const deleteQualityUrl = '/evaluation/qualityManage/deleteDetail';
// 修改品质考核的启用禁用状态
const changeQualityAssessmentEnableUrl = '/evaluation/qualityManage/modifyDetailEnableState';
// 上传品质考核项
const uploadQualityAssessmentItemUrl = '/evaluation/qualityManage/uploadItem';


// 添加文件夹
const createFolder = '/evaluation/repositoryFile/createFolder';
// 修改文件夹
const editFolder = '/evaluation/repositoryFile/editFolder';
// 上传资料
const addFile = '/evaluation/repositoryFile/addFile';
// 查询资料夹
const queryAllFolder = '/evaluation/repositoryFile/queryAllFolder';
// 资料夹启用或禁用
const updateFolderIsEnable = '/evaluation/repositoryFile/updateFolderIsEnable';
// 删除资料夹
const deleteFolderIsEnable = '/evaluation/repositoryFile/deleteFolderIsEnable';
// 查看资料
const listFiles = '/evaluation/repositoryFile/listFiles';
// 查看记录
const queryAccessLog = '/evaluation/repositoryFile/queryAccessLog';
// 修改文件名
const editFileName = '/evaluation/repositoryFile/editFileName';
// 删除文件
const deleteFile = '/evaluation/repositoryFile/deleteFile';
// 根据文件名查询文件
const selectFileByName = '/evaluation/repositoryFile/selectFileByName';
// 员工查看资料
const selectAccessLog = '/evaluation/repositoryFile/selectAccessLog';
// 员工查看文件夹
const queryFolder = '/evaluation/repositoryFile/queryFolder';
// 员工根据文件名查询文件夹
const selectFolderByIsEnable = '/evaluation/repositoryFile/selectFolderByIsEnable';
// 添加品质管理检查人
const addEvaluatorUsersUrl = '/evaluation/qualityManage/insertEvaluatorUsers';
// 删除品质管理检查人
const deleteEvaluatorUsersUrl = '/evaluation/qualityManage/deleteEvaluatorUsers';
// 上传文件
const uploadFileUrl = '/uploadFile';
// 查询权限
const selectPermission = '/evaluation/repositoryFile/selectPermission';
// OSS STS授权接口
const ossKey = "/account/staff/ossKey"

// 查询品质考核项
const queryQualityAssessmentItemsUrl = '/evaluation/qualityManage/adminQueryItem';
// 删除品质考核项
const deleteQualityAssessmentItemUrl = '/evaluation/qualityManage/deleteItem';
// 更新品质考核项
const updateQualityAssessmentItemUrl = '/evaluation/qualityManage/updateItem';
// 查询可以受检的部门
const queryEvaluatorUserDutyTypeUrl = '/evaluation/qualityManage/queryEvaluatorUserDutyType';
// 生成品质报告
const createQualityReportUrl = '/evaluation/qualityManage/insertReport';
// 查询品质考核项记录
const adminQueryItemLogUrl = '/evaluation/qualityManage/adminQueryItemLog';
// 查询品质考核记录
const queryReportUrl = '/evaluation/qualityManage/adminQueryReport';
// 删除品质报告
const deleteReportUrl = '/evaluation/qualityManage/deleteReport';
// 查询品质报告详情
const adminQueryReportDetailUrl = '/evaluation/qualityManage/adminQueryReportDetail';
// 编辑品质报告
const editorQualityReportUrl = '/evaluation/qualityManage/editorReport';

// 小程序图表数据接口
const appletsDataUrl = '/statistics/chart/wechatData';
// 运营数据中活动数据接口
const activityDateUrl = '/social/activity/operationQueryTitleData';
// 运营数据中问卷数据接口
const surveyDataUrl = "/survey/survey/queryRegionCodeData";
//园区活跃用户数据接口
const regionAvtiveUserDataUrl = "/statistics/chart/regionUVData";
//园区业务数据查询接口
const regionBizDataUrl = "/statistics/chart/regionBizData";
//运营数据中投诉详情数据接口
const complaintDataUrl = "/ticket/ticket/queryComplaint";
//查询单个问卷数据
const querySingleSurveyUrl = "/survey/survey/adminSingleQuery";
// 会议室订单退款
const refundMeetingRoomOrderUrl = '/public/meetingRoom/refund';
// 获取设置员工领班记录
const querySetStaffRecordUrl = '/ticket/foreman/querySetStaffRecord';
// 设置员工开门
const setStaffOpenRoomUrl = '/public/openRoom/setStaffOpenRoom';
// 获取可开门的员工
const getIsSettOpenRoomUrl = '/public/openRoom/getIsSettOpenRoom';

// 查看苹果红包数据
const redPackByIdUrl = '/social/redPackManage/queryRedPackById';
// 添加小程序菜单
const addMiniProgramMenuUrl = '/public/menu/addMenu';
// 修改小程序菜单
const modifyMiniProgramUrl = '/public/menu/modifyMenu';
// 查询小程序菜单
const queryMiniProgramUrl = '/public/menu/queryMenu';
// 删除小程序菜单
const deleteMiniProgramUrlUrl = '/public/menu/deleteMenu';
// 查询苹果红包下拉列表
const queryRedPackListUrl = '/social/redPackManage/queryRedPackList';

// 删除房间照片
const deleteContractRoomPictureUrl = '/lease/contractManage/deleteContractRoomPicture';
//默认租控图列表  最新添加的楼栋
const DefaultRentControlUrl = `/lease/contractManage/queryNestRoomRent`;
// 直播地址保存
const saveOrModifyLiveUrl = '/public/live/saveOrModifyUrl';
// 查询直播地址
const queryLiveUrlByRegionCodeUrl = '/public/live/queryByRegionCode';


//企业列表
const queryIndividualList = '/lease/enterpriseIndividual/queryIndividualList';
//添加企业
const addEnterprise = '/lease/enterpriseIndividual/addEnterprise';
//添加个人
const addIndividual = '/lease/enterpriseIndividual/addIndividual';
//修改企业信息
const editEnterprise = '/lease/enterpriseIndividual/editEnterprise';
//修改个人信息
const editIndividual = '/lease/enterpriseIndividual/editIndividual';
//删除企业个人接口
const removeIndividual = '/lease/enterpriseIndividual/removeIndividual';
//添加业主或租客的搜索框
const searchEnterpriseIndividual = '/lease/enterpriseIndividual/searchEnterpriseIndividual';
//修改对接人接口
const editDeputyPhone = '/lease/enterpriseIndividual/editDeputyPhone';
//查询个人或企业 是否绑定过业主|租客
const queryLesseeExists = '/lease/enterpriseIndividual/queryLesseeExists';

//查询个人或企业 是否绑定过业主|租客
const queryRoomInfoUrl = '/lease/room/queryRoomInfo';

//根据手机号查看楼内用户
const queryUserByPhone = '/account/user/queryUserByPhone';
//修改对接人搜索
const queryUserByPhoneOrName = '/account/user/queryUserByPhoneOrName';
//查询对接人列表
const queryIdentityList = '/account/user/queryIdentityList';

//添加个人查信息
const personalNameSearchUrl = "/lease/enterpriseIndividual/personalNameSearch"





//查询文件列表
// const queryFileList = 'social/filedownload/singleselect';
//删除文件
const removeFile = '/social/filedownload/delete';
//上传文件
const importFileUrl='/social/filedownload/upload';



//保存客户
const leaseCrmUploadExcelUrl = '/lease/crm/uploadExcel';
//添加指南
const addGuidanceUrl = '/social/banshizhinan/uploadSelect';
//修改指南
const editGuidanceUrl = '/social/banshizhinan/uploadSelect';
//删除指南
const deleteGuidanceUrl = '/social/banshizhinan/delete';
//查询指南
const getGuidanceUrl = '/social/banshizhinan/findTitle';
//查询文件标题
const queryGuidanceInfoUrl='/social/banshizhinan/selectText';

//清空离职员工的照片
const removeStaffPictureUrl = '/account/staff/removeStaffPicture';

export {
    leaseCrmUploadExcelUrl,
    DefaultRentControlUrl,
    adminQueryMeetingRoomByIdUrl,
    refundMeetingRoomOrderUrl,
    sendSmsCodeUrl,
    signInUrl,
    getMenuUrl,
    createDepartmentUrl,
    queryDepartmentUrl,
    deleteDepartmentUrl,
    editDepartmentUrl,
    getProvincesUrl,
    getRegionalismUrl,
    queryRegionListUrl,
    deleteRegionUrl,
    createRegionUrl,
    editRegionUrl,
    creatBuildingUrl,
    deleteBuildingUrl,
    editBuildingUrl,
    queryBuildingUrl,
    createResourceUrl,
    disableResourceUrl,
    queryResourceUrl,
    enableResourceUrl,
    editResourceUrl,
    createRoleUrl,
    editRoleUrl,
    downloadHandoverLogUrl,
    deleteRoleUrl,
    queryRoleUrl,
    deleteAttachmentUrl,
    setupRoleResourcesUrl,
    queryRoleResourcesUrl,
    queryDepartmentStaffUrl,
    resignStaffUrl,
    editStaffUrl,
    addStaffUrl,
    uploadPhotoUrl,
    setupUserRoleUrl,
    queryUserRoleUrl,

    reopenTicketUrl,
    assignTicketUrl,
    queryTickerUrl,
    editTicketUrl,
    createTicketUrl,
    setTicketTimeoutUrl,
    getTicketTimeoutUrl,
    deleteTicketUrl,
    meetingRoomOfflinePaymentUrl,
    listProfessionalItemUrl,
    listGeneralFormUrl,
    queryAutomationConfigRulesUrl,
    setupAutomationConfigRulesUrl,
    disableGeneralFormUrl,
    disableProfessionalItemUrl,
    disableGeneralItemUrl,
    addGeneralFormUrl,
    editGeneralFormUrl,
    addGeneralItemUrl,
    editGeneralItemUrl,
    addProfessionalItem,
    editProfessionalItem,
    professionalDetailsUrl,
    automationDetailsUrl,
    generalDetailsUrl,
    generalSummaryUrl,
    professionalSummaryUrl,
    queryTicketUrl,
    automationSummaryUrl,
    setupVendorConfigUrl,
    editVendorConfigUrl,
    queryAttendanceVendorUrl,
    queryAttendanceLogUrl,
    queryHandoverLogUrl,
    passingLogUrl,
    setValhallaAdConfigUrl,
    deleteQuestionnaireUrl,
    changeEnableQuestionnaireUrl,
    enterpriseOfflinePaymentUrl,
    ticketOfflinePaymentUrl,
    signForDeliveryUrl,
    createRoomUrl,
    queryRoomUrl,
    stateRoomUrl,
    editRoomUrl,
    deleteRoomUrl,
    queryTenantUrl,
    createTenantUrl,
    editTenantInfoUrl,
    deleteTenantInfoUrl,
    roomStateDetailUrl,
    pactDetailsUrl,
    createContractUrl,
    queryContractListUrl,
    cancelContractUrl,
    terminateContractUrl,
    transferContractUrl,
    preUpdateContractUrl,
    preRenewContractUrl,
    queryContractDetailUrl,
    queryContractRoomPictureUrl,
    addContractAttachmentsUrl,
    setBillNotifyConfigUrl,
    queryBillNotifyConfigUrl,
    queryBillMenuTipsStatusUrl,
    queryBillListUrl,
    sendBillPaymentNotifyUrl,
    queryBillDetailUrl,
    commitPaymentUrl,
    queryRoomRentUrl,
    billUrl,
    calcContractRentUrl,
    queryRenterBindRoomsUrl,
    financeQueryUrl,
    adminOfflinePaymentUrl,
    adminOfflinecreateUrl,
    addMeetingRoomUrl,
    adminQueryMeetingRoomUrl,
    refundMeetingRoomUrl,
    queryMeetingRoomOrderUrl,
    deleteMeetingRoomUrl,
    changeEnableMeetingRoomUrl,
    updateMeetingRoomUrl,
    queryMeetingRoomDetailUrl,
    adminQueryOrderUrl,
    adminQueryRefundOrderUrl,
    addVisitingItemsUrl,
    adminQueryItemsUrl,
    changeVisitingServiceEnableStateUrl,
    visitingServiceNotificationUrl,
    deleteVisitingServiceItemUrl,
    addVisitingServiceItemUrl,
    sweepCodeToOpenDoorlUrl,
    closeDoorSettlementUrl,
    getfinanceQueryUrl,
    editVisitingServiceItemUrl,
    queryVisitingServiceItemDetailUrl,
    getadminQueryOrderUrl,
    signContractUrl,
    getadminQueryItemsUrl,
    queryMeetingRoomBillUrl,
    queryVisitingServiceBillUrl,
    addItemUrl,
    amendVisitingServiceItemUrl,
    deleteEnterpriseServiceItemUrl,
    queryEnterpriseServiceItemDetailUrl,
    changeEnableEnterpriseServiceItemUrl,
    addSurveyUrl,
    adminQueryUrl,
    adminQueryCensusUrl,
    adminQueryCommitLogUrl,
    adminQueryOptionDetailUrl,
    adminQueryPostlUrl,
    adminQuerySensitiveUrl,
    adminQueryDelReplyUrl,
    adminQueryBlockUrl,
    adminDelReplyUrl,
    adminBlockUrl,
    adminIsTopUrl,
    adminDelPostUrl,
    adminQueryReplyUrl,
    getAdminQueryUrl,
    adminDelUrl,
    adminIsEnableUrl,
    getadminQueryUrl,
    deleteActivityUrl,
    addUrl,
    addActivityUrl,
    downloadQrCodeUrl,
    userQueryAnPostUrl,
    adminCancelAuditUrl,
    addMallItemUrl,
    editMallItemUrl,
    changeMallAvailableUrl,
    queryMallItemsUrl,
    queryMallOrderUrl,
    queryMallCommodityUrl,
    queryPickupLocationUrl,
    addPickupLocationUrl,
    editPickupLocationUrl,
    changePickupEnableUrl,
    refundOrderUrl,
    uploadEnterpriseAnnexUrl,
    querySocialServiceAppleProduceDataUrl,
    querySocialServiceAppleRedPacketDataUrl,
    querySocialServiceAppleRedPacketTitleDataUrl,
    querySocialServiceAppleRedPacketDetailsDataUrl,
    createAppleRedPackUrl,
    queryAppleRedPackUrl,
    updateAppleRedPacketStateUrl,
    setAppleConfigUrl,
    getAppleConfigUrl,
    addRedPacketActivityUrl,
    querySocialServiceAppleSellDataUrl,
    querySocialServiceSellSourceDataUrl,
    querySocialServiceSellTitleDataUrl,
    socialServiceBlockExchangeUrl,
    socialIssueExchangeUrl,
    queryQuestionnaireCensusUrl,
    queryTicketPropertyDataUrl,
    queryTicketPropertyChartDataUrl,
    queryEvaluationPropertyDataUrl,
    queryEvaluationPropertyChartDataUrl,
    queryAccountPropertyDataUrl,
    queryAccountPropertyChartDataUrl,
    refundVisitingOrderUrl,
    queryIsDivisionRoomUrl,
    queryRedDotUrl,
    refundTicketUrl,
    refundEnterpriseUrl,
    saveConfigLeaseCrmUrl,
    queryCrmConfigUrl,
    getCustomerLeadsUrl,
    queryPublicCustomerUrl,
    uploadExcelUrl,
    lockedToOpportunityUrl,
    addFollowUpLogUrl,
    queryLeadContactsUrl,
    queryOpportunityUrl,
    addOpportunityUrl,
    releaseOpportunityUrl,
    querySubscriptionsUrl,
    auditSubscribeUrl,
    queryCustomerInfoUrl,
    updateCustomerInfoUrl,
    queryCustomerFollowLogUrl,
    deleteContactUrl,
    setupDefaultContactsUrl,
    addLeadContactsUrl,
    editLeadContactsUrl,
    signLeadContractUrl,
    deleteCustomerUrl,
    transferLeadUrl,
    queryBuildingRoomsUrl,
    addIntentionRoomsUrl,
    queryPublicLeadsUrl,
    queryIntentionRoomsUrl,
    publicToOpportunityUrl,
    createFolder,
    editFolder,
    addFile,
    queryAllFolder,
    updateFolderIsEnable,
    deleteFolderIsEnable,
    listFiles,
    queryTicketCountByTimeUrl,
    queryAccessLog,
    editFileName,
    deleteFile,
    selectFileByName,
    selectAccessLog,
    queryFolder,
    selectFolderByIsEnable,
    selectPermission,
    ossKey,
    queryTempCustomerPoolUrl,
    uploadFileUrl,
    addQualityAssessmentItemUrl,
    queryQualityAssessmentUrl,
    editQualityAssessmentUrl,
    deleteQualityUrl,
    changeQualityAssessmentEnableUrl,
    uploadQualityAssessmentItemUrl,
    setRestrictConfigUrl,
    queryRestrictConfigUrl,
    addEvaluatorUsersUrl,
    deleteEvaluatorUsersUrl,
    queryQualityAssessmentItemsUrl,
    deleteQualityAssessmentItemUrl,
    updateQualityAssessmentItemUrl,
    queryEvaluatorUserDutyTypeUrl,
    createQualityReportUrl,
    adminQueryItemLogUrl,
    queryTicketCountUrl,
    queryReportUrl,
    deleteReportUrl,
    adminQueryReportDetailUrl,
    editorQualityReportUrl,
    appletsDataUrl,
    activityDateUrl,
    surveyDataUrl,
    regionAvtiveUserDataUrl,
    regionBizDataUrl,
    complaintDataUrl,
    querySingleSurveyUrl,
    queryReportPropertyDataUrl,
    changeBuildingStateUrl,
    changeRoomStateUrl,
    userFillSurveyUrl,
    querySetStaffRecordUrl,
    setStaffOpenRoomUrl,
    getIsSettOpenRoomUrl,
    addPCLoginUrl,
    queryPCLoginUrl,
    redPackByIdUrl,
    addMiniProgramMenuUrl,
    modifyMiniProgramUrl,
    queryMiniProgramUrl,
    deleteMiniProgramUrlUrl,
    queryRedPackListUrl,
    deleteAnnexUrl,
    queryRoomRentUrld,
    deleteContractRoomPictureUrl,
    getAttendanceSummaryUrl,
    queryEnableTicketTypeUrl,
    updateEnableTicketTypeUrl,
    queryLesseeRoomByLesseeIdUrl,
    saveOrModifyLiveUrl,
    queryLiveUrlByRegionCodeUrl,
    queryIndividualList,
    queryUserByPhone,
    queryIdentityList,
    addEnterprise,
    removeIndividual,
    addIndividual,
    searchEnterpriseIndividual,
    editEnterprise,
    editIndividual,
    queryUserByPhoneOrName,
    editDeputyPhone,
    queryLesseeExists,
    // queryFileList,
    removeFile,
    importFileUrl,
    addGuidanceUrl,
    editGuidanceUrl,
    deleteGuidanceUrl,
    getGuidanceUrl,
    queryGuidanceInfoUrl,
    queryRoomInfoUrl,
    queryRoomRentSimplifyUrl,
    querySubscriptionsdUrl,
    assigningPublicClientUrl,
    queryRoomRentUrls,
    queryLesseeBindRoomsUrl,

    personalNameSearchUrl,

    removeStaffPictureUrl,
    queryAllDepartmentUrl,
    queryAllDepartmentStaffUrl,
    queryMenuByUserUrl,
    queryAllDivisionRoomUrl,
    entryUserUrl,
    queryStaffByDutyTypesUrl
}

