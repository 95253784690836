import {loadView} from './loadFn';

export default {
    path: "WaterElectricityMeter",
    component: loadView("WaterElectricityMeter/WaterElectricityMeter"),
    children: [
        {
            path: "reading",
            name: "reading",
            meta: {
                title: '表组管理'
            },
            component: loadView("WaterElectricityMeter/WaterElectricityMeterReading"),
        },
        {
            path: "preStorage",
            name: "preStorage",
            component: loadView("WaterElectricityMeter/WaterElectricityMeterPreStorage")
        },
        {
            path: "consume",
            component: loadView("WaterElectricityMeter/WaterElectricityMeterConsume"),
            children: [
                {
                    path: "",
                    name: "waterMeterConsume",
                    meta: {
                        title: '水表'
                    },
                    component: loadView("WaterElectricityMeter/WaterMeterConsume")
                },
                {
                    path: "waterElectricityConsume",
                    name: "waterElectricityConsume",
                    meta: {
                        title: '电表'
                    },
                    component: loadView("WaterElectricityMeter/WaterElectricityConsume")
                }

            ]
        },
        {
            path: `TableGroupManagement`,
            name: "TableGroupManagement",
            meta: {
                title: "表组管理"
            },
            component: loadView("WaterElectricityMeter/TableGroupManagement"),
        },
        {
            path: `consume/history/:id`,
            name: "history",
            props: (route) => ({
                id: +route.params.id,
            }),
            meta: {
                title: "历史业主/租客"
            },
            component: loadView("WaterElectricityMeter/WaterElectricityMeterHistory"),
        }, {
            path: `consume/cumulative/:id`,
            name: "Cumulative",
            props: (route) => ({
                id: +route.params.id,
            }),
            meta: {
                title: "累计消耗金额"
            },
            component: loadView("WaterElectricityMeter/WaterElectricityMeterCumulative"),

        }, {
            path: 'setUp',
            name: 'setUp',
            component: loadView("WaterElectricityMeter/WaterElectricityMeterSetUp")
        }, {
            path: 'bill',
            name: 'bill',
            component: loadView("WaterElectricityMeter/PoolBill/PoolBill"),
            meta: {
                title: "水电公摊"
            },
            children: [
                {
                    path: '',
                    name: 'summaryManager',
                    component: loadView("WaterElectricityMeter/PoolBill/Summary/SummaryManager"),
                    meta: {
                        title: "汇总账单",
                        checkedTaBarNumer:1
                    },
                },
                {
                    path: 'billManger',
                    name: 'billManger',
                    component: loadView("WaterElectricityMeter/PoolBill/Bill/BillManger"),
                    meta: {
                        title: "账单",
                        checkedTaBarNumer:2
                    },
                }
            ]
        }
    ]
}
