// 查询省市区
const queryProvinceUrl = "/lease/regionalism/queryProvince";
// 添加抓取网站
const addWebUrl = "/policy/website/add";
// 删除抓取网站
const deleteWebUrl = "/policy/website/delete";
// 修改抓取网站
const updateWebUrl = "/policy/website/update";
// 查询抓取网站列表
const queryByConditionUrl = "/policy/website/queryByCondition";
// 导入Excel
const importExcelUrl = "/policy/website/importExcel";
// 查询测试抓取数据列表
const queryByGrabWebsiteUrl = "/policy/grabWebsite/queryByCondition";
// 设置抓取年份下限
const setGrabYearUrl = "/policy/topSearch/setGrabYear";
// 查询测试抓取年份下限和自动抓取发布时间
const querySetDateUrl = "/policy/topSearch/querySetDate";
// 查询抓取失败原因
const queryFailCauseUrl = "/policy/grabWebsite/queryFailCause";
// 查询政策详情信息
const queryPolicyInfoUrl = "/policy/grabWebsite/queryById";
//测试抓取网站
const testCrawSiteUrl = "/policy/grabWebsite/testCrawSite";
// 设置自动抓取时间发布时间
const setupTimeUrl = "/policy/topSearch/setupTime";
// 查询待发布-已发布政策
const queryPolicyByConditionUrl = "/policy/grabWebsite/queryPolicyByCondition";
// 是否启用自动抓取
const enableAutomaticGrabUrl = "/policy/topSearch/enableAutomaticGrab";
// 查询政策
const queryByIdPolicyUrl = "/policy/grabWebsite/getById";
// 手动添加政策时 查询网站列表接口
const addPolicySelectUrlNameUrl = "/policy/grabWebsite/addPolicySelectUrlName";
// 修改政策
const updatePolicyUrl = "/policy/grabWebsite/update";
// 手动添加政策接口
const addHandPoliceUrl = "/policy/grabWebsite/add";
// 发布政策接口
const sendPolicyUrl = "/policy/grabWebsite/sendPolicy";
// 删除政策
const deletePolicyUrl = "/policy/grabWebsite/delete";
// 获取热搜词
const getSearchUrl = "/policy/topSearch/getSearch";
// 添加热搜词
const addSearchUrl = "/policy/topSearch/add";
// 上传附件接口
const uploadToFileUrl = "/policy/grabWebsite/uploadToFile";
// 设置网站过滤词
const setFilterTextUrl = "/policy/website/setFilterText";
// 查询网站过滤词
const selectFilterTextUrl = "/policy/website/selectFilterText";
// 下载模板接口
const downLoadFileUrl = "/policy/website/downLoadFile";

export {
    queryProvinceUrl,
    addWebUrl,
    deleteWebUrl,
    updateWebUrl,
    queryByConditionUrl,
    importExcelUrl,
    queryByGrabWebsiteUrl,
    queryFailCauseUrl,
    setGrabYearUrl,
    testCrawSiteUrl,
    querySetDateUrl,
    queryPolicyInfoUrl,
    setupTimeUrl,
    queryPolicyByConditionUrl,
    enableAutomaticGrabUrl,
    queryByIdPolicyUrl,
    addPolicySelectUrlNameUrl,
    updatePolicyUrl,
    addHandPoliceUrl,
    sendPolicyUrl,
    deletePolicyUrl,
    getSearchUrl,
    addSearchUrl,
    uploadToFileUrl,
    setFilterTextUrl,
    selectFilterTextUrl,
    downLoadFileUrl,
}