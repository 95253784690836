//办事指南和资料下载
 const  queryFileList ='/social/filedownload/showAll';
 //文件上传功能接口
 const uploadUrl = '/social/filedownload/upload';
 //删除数据接口
 const deleteFileListUrl = '/social/filedownload/delete/';
 //办事指南数据查询接口
 const showAllUrl = '/social/banshizhinan/showAll';
 //添加办事指南接口
 //修改办事指南接口
 const insertDataUrl = '/social/banshizhinan/insertData';
 const uploadSelectDataUrl ='/social/banshizhinan/uploadSelect';
 //删除办事指南接口
 const deleteFileNUrl = '/social/banshizhinan/delete';
export {
    queryFileList,
    uploadUrl,
    deleteFileListUrl,
    showAllUrl,
    insertDataUrl,
    deleteFileNUrl,
    uploadSelectDataUrl
}