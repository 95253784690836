import { loadView } from "@/router/loadFn";

export default [{
    path: "projection",
    name: "projection",
    meta: {
        title: '投放设置'
    },
    component: loadView("Projection/ProjectionManage")
}, {
    path: 'projectionMap',
    name: "projectionMap",
    meta: {
        title: '设备摆放地点'
    },
    component: loadView("Projection/ProjectionMap")
}]