//添加公告
const addBannerUrls = "/public/banner/addBanner";
//修改公告
const editBannerUrls = "/public/banner/editBanner";
//删除公告
const deleteBannerUrls = "/public/banner/deleteBanner";
//查询公告
const queryBannerUrls = "/public/banner/queryBanner";
//上下架公告
const putAwayBannerUrls = "/public/banner/putAwayBanner";


//-------身份证绑定管理-------


const queryBindIdCardUrls = "/account/user/queryBindIdCard";
const unbindIdCardUrls = "/account/user/unbindIdCard";
const queryUnbindIdCardUrls = "/account/user/queryUnbindIdCard";

export {
    addBannerUrls,
    editBannerUrls,
    deleteBannerUrls,
    queryBannerUrls,
    putAwayBannerUrls,
    unbindIdCardUrls,
    queryUnbindIdCardUrls,
    queryBindIdCardUrls,
}
