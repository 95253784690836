import fly from "./flyio/flyConfig";
// 图片、视频展示域名
const commonImgOssDomain = "https://alioss.techops.cn/";
// 图片、视频下载域名
const downloadFileOssDomain = "https://cosmos-img.oss-cn-beijing.aliyuncs.com/";
// 二维码下载域名
const downloadQrCodeOssDomain =
	"https://cosmos-qrcode.oss-cn-beijing.aliyuncs.com/";
// 获取园区二维码
const getRegionQrCodeUrl = "/lease/estateManage/getRegionQrCode";

export * from "./defaultUrl.js";
export * from "./displayDiagram.js";
export * from "./deviceManageUrl.js";
export * from "./attendanceScheduleUrl.js";
export * from "./inspectionServiceUrl";
export * from "./meterRequestUrl";
export * from "./warehouseUrl";
export * from "./checkRoomUrl";
export * from "./monitorUrl";
export * from "./BigDataUrl";
export * from "./accessCardUrl";
export * from './policyUrl';
export * from "./parkingUrl";
export * from "./hydropowerUrl";
export * from "./environmentalMonitoringUrl";
export * from "./dronesUrl";
export * from "./propertyContractPaymentUrl";
export * from "./robotUrl";
export * from "./decoration";
export * from "./buildingBook";
export * from './projectionUrl';
export *  from './serviceGuide';
export *  from "./panelManagement";
export * from './elevatorUrl';
export * from './ticketUrl';
export {
	fly,
	commonImgOssDomain,
	downloadFileOssDomain,
	downloadQrCodeOssDomain,
	getRegionQrCodeUrl,
};
