//水电表请求API
//查询水表读数||消耗记录
const waterReadingUrl = "/meter/waterMeter/selectWaterTon";
//终止合同时信息回显
const terminationContractUrl = "/lease/contractManage/terminateContractQueryInfo";
//查询水表，绑定房间时使用
const getAllWaterMetersUrl ="/meter/waterMeter/selectWaterMeterRelevance";
// 查询电表，绑定房间时使用
const selectWaterMeterRelevanceUrl = "/meter/electricityMeter/selectWaterMeterRelevance";
//查询水电表是否被绑定
const isHydropowerBindingUrl = "/lease/estateManage/queryRoomByNo";
//查询预存记录列表
const hydropowerPreStorageUrl = '/meter/payRecord/selectPayRecordList';
//根据租户id和房间id查询公司及合同信息接口
const hydropowerContractInformationUrl = '/lease/contractManage/selectLesseeInfoById';
//查询公司2
const queryLesseeInfoByIdWaUrl = '/lease/contractManage/queryLesseeInfoById';
//账单金额总计
const billAmountStatisticsUrl = '/meter/collect/bill/billAmountStatistics';
//线下充值
const hydropowerDrawABillUrl = '/meter/payRecord/offlinePayment';
//线下缴费时 查询所有租客接口
const hydropowerGetTenantUrl = '/lease/lesseeManage/selectLesseeInfo';
//获取对应房间ID对应的水电表
const hydropowerRoomIdUrl = '/meter/relevancyUser/selectByLesseeId';
//查询第三方相关信息接口
const  hydropowerGetInfoUrl = "/meter/basicSetup/selectBasicSetup";
//开发票
const hydropowerInvoiceUrl = '/meter/payRecord/openInvoice';
//查询水表历史租客
const waterHistoryListUrl = `/meter/relevancyUser/selectHistoryRecord`;
//查询汇总账单
const queryEquallySharedCollectUrl = '/meter/collect/queryEquallySharedCollect';
//添加公摊账单
const addEquallySharedBillUrl = '/meter/collect/bill/addEquallySharedBill';
//修改公摊账单
const modifyEquallySharedBillUrl = '/meter/collect/bill/modifyEquallySharedBill';
//查询账单变更记录
const billLogUrl ='/meter/collect/bill/log/queryBillLog';
//查询累计消耗金额详情
const waterUmulativeConsumptionUrl = '/meter/relevancyUser/selectAccruingAmount';
//修改或新加 第三方信息
const setHydropowerUrl = `/meter/basicSetup/setBasicSetup`;
//修改房间绑定的水电表
const hydropowerModifyBindingUrl = '/lease/estateManage/editRoomMeter';
//查询第三方关联信息
const getHydropowerPartyInformationUrl =`/meter/factorySetting/queryFactoryInfo`;
//查询当前的第三方信息接口
const getHydropowerPartyInfoUrl = `/meter/factorySetting/queryFactory`;
//保存第三方信息接口
const preservationHydropowerUrl=`/meter/factorySetting/saveFactoryInfo`;
//查询水表出厂数据是否正常
const queryWaterStateWUrl = '/meter/waterMeter/queryWaterState';
// 查询电表读数/消耗记录
const selectWaterTonUrl = '/meter/electricityMeter/selectWaterTon';
// 查询电表信息是否正常
const selectRecordUrl = "/meter/electricityMeter/queryElectricityState";
const allotSitesUserUrl = '/lease/crm/allotSitesUser';
//查询账单
const queryEquallySharedBillUrl = '/meter/collect/bill/queryEquallySharedBill';
//删除公摊汇总账单
const deleteEquallySharedBillUrl = '/meter/collect/bill/deleteEquallySharedBill';
//线下缴费
const billOfflinePaymentUrl = '/meter/collect/bill/billOfflinePayment';
//水表设置
const modifyWaterUrl = '/meter/waterMeter/modifyWater';
//表组相关
const canPayUrl = '/meter/meterGroup/relevanceGroup';
//查询表组列表
const queryMeterGroupUrl = "/meter/meterGroup/queryMeterGroup";
//添加表组接口
const addMeterGroupUrl = "/meter/meterGroup/addMeterGroup";
//删除表组接口
const deleteMeterGroupUrl = "/meter/meterGroup/delete";
//修改表组接口
const editMeterGroupUrl = "/meter/meterGroup/editMeterGroup";
//修改表备注
const editMeterCommitUrl = "/meter/meterGroup/editMeterCommit";
//修改表箱号
const editMeterBoxNoUrl = "/meter/meterGroup/editMeterBoxNo"
//修改位置
const editMeterLocationUrl = "/meter/meterGroup/editMeterLocation"

//表组下表
const queryMeterByGroupIdUrl = "/meter/meterGroup/queryMeterByGroupId"
//企业下表
const queryDosageByLesseeIdUrl = "/meter/relevancyUser/queryDosageByLesseeId";

//查询一段时间内的水费
const queryWaterBalanceUrl = "/meter/relevancyUser/queryWaterBalance";
//设置余额
const editWaterBalanceUrl = "/meter/relevancyUser/editWaterBalance";

//添加或修改水电公摊设置
const addOrModifySharedConfigUrl = '/meter/shared/config/v3/addOrModifySharedConfig';
//查询水电费公摊设置
const querySharedConfigUrl = '/meter/shared/config/v3/querySharedConfig';
//电表设置
const modifyElectricityUrl = '/meter/electricityMeter/modifyElectricity'
//查询园区总用水用电量
const queryRegionWaterAndElectricitySumUrl = '/meter/collect/queryRegionWaterAndElectricitySum'
//查询园区公摊数据
const queryRegionEquallySharedDataUrl = '/meter/collect/queryRegionEquallySharedData';
//添加公摊汇总账单
const addEquallySharedCollectUrl = '/meter/collect/addEquallySharedCollect';
const modifyEquallySharedCollectUrl = '/meter/collect/modifyEquallySharedCollect';
//删除汇总账单
const deleteEquallySharedCollectUrl = '/meter/collect/deleteEquallySharedCollect';
//下发账单
const sendBillUrl = '/meter/collect/sendBill';
export {
    addEquallySharedCollectUrl,
    queryRegionEquallySharedDataUrl,
    modifyElectricityUrl,
    allotSitesUserUrl,
    waterReadingUrl,
    terminationContractUrl,
    getAllWaterMetersUrl,
    selectWaterMeterRelevanceUrl,
    isHydropowerBindingUrl,
    hydropowerPreStorageUrl,
    hydropowerContractInformationUrl,
    hydropowerDrawABillUrl,
    hydropowerGetTenantUrl,
    deleteEquallySharedBillUrl,
    queryRegionWaterAndElectricitySumUrl,
    hydropowerRoomIdUrl,
    hydropowerGetInfoUrl,
    hydropowerInvoiceUrl,
    billAmountStatisticsUrl,
    queryLesseeInfoByIdWaUrl,
    canPayUrl,
    modifyEquallySharedCollectUrl,
    waterHistoryListUrl,
    waterUmulativeConsumptionUrl,
    setHydropowerUrl,
    hydropowerModifyBindingUrl,
    getHydropowerPartyInformationUrl,
    preservationHydropowerUrl,
    getHydropowerPartyInfoUrl,
    queryWaterStateWUrl,
    selectWaterTonUrl,
    selectRecordUrl,
    queryMeterGroupUrl,
    modifyEquallySharedBillUrl,
    addMeterGroupUrl,
    deleteMeterGroupUrl,
    editMeterGroupUrl,
    editMeterCommitUrl,
    editMeterBoxNoUrl,
    editMeterLocationUrl,
    queryMeterByGroupIdUrl,
    queryDosageByLesseeIdUrl,
    queryWaterBalanceUrl,
    editWaterBalanceUrl,
    addOrModifySharedConfigUrl,
    querySharedConfigUrl,
    queryEquallySharedBillUrl,
    queryEquallySharedCollectUrl,
    modifyWaterUrl,
    billLogUrl,
    deleteEquallySharedCollectUrl,
    sendBillUrl,
    addEquallySharedBillUrl,
    billOfflinePaymentUrl
}
