import {loadView} from "@/router/loadFn";

export default {
    path: "Announcement",
    component: loadView("Announcement/AnnouncementContainerView"),
    children: [
        {
            name: "Announcement",
            path: "",
            component: loadView("Announcement/Announcement"),
        },
        {
            name: "turnstileAnnouncement",
            path: "_turnstile",
            component: loadView("Announcement/TurnstileAnnouncement"),
        },
        {
            name: "loginPage",
            path: "loginPage",
            component: loadView("Announcement/loginPage"),
        }
    ],
}
